import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { withTranslate } from 'react-redux-multilingual';

class HeaderOne extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-dropdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                   /*
                   //DISABLE MENUS
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                    */
                }
            }
        }
        
        return(
            <div>
                <header className="header header--transparent color--light">
                    <div className="header-wrapper">
                        <div className="branding">
                            <div className="logo">
                                <Link to={`${process.env.PUBLIC_URL}/`}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logo/light-logo.png`} alt="App landing" />
                                </Link>
                            </div>
                        </div>
                        <div className="page-navigation">
                            <nav className="mainmenu-nav">
                                <ul className="mainmenu">
                                    <li className="has-dropdown"><a href="#home"><span>Home</span></a>
                                       {/*
                                       <ul className="submenu">
                                           <li><a href="/branding">branding</a></li>
                                            <li><a href="/sass">sass</a></li>
                                            <li><a href="/app-showcase">app showcase</a></li>
                                            <li><a href="/software">software</a></li>
                                            <li><a href="/startups">startups</a></li>
                                            <li><a href="/payment">payment</a></li>
                                            <li><a href="/app-landing">app landing</a></li>
                                            <li><a href="/digital-agency">digital agency</a></li>
                                            <li><a href="/ebook">ebook</a></li>
                                            <li><a href="/clinic">clinic</a></li>
                                            <li><a href="/digital-store">digital store</a></li>
        
                                        </ul> 
                                        */}
                                    </li> 
                                    <li className="has-dropdown"><a href="#features"><span>Features</span></a>
                                    
                                         {/*
                                        <ul className="submenu">
                                            <li><a href="/button">button</a></li>
                                            <li><a href="/accordion">accordion</a></li>
                                            <li><a href="/attribute-list">attribute list</a></li>
                                            <li><a href="/box-icon">box icon</a></li>
                                            <li><a href="/call-to-action">call to action</a></li>
                                            <li><a href="/dividers">dividers</a></li>
                                            <li><a href="/counters">counters</a></li>
                                            <li><a href="/gradation">gradation</a></li>
                                            <li><a href="/message-box">message box</a></li>
                                            <li><a href="/popup-video">popup video</a></li>
                                            <li><a href="/progress-bar">progress bar</a></li>
                                            <li><a href="/lists">lists</a></li>
                                            <li><a href="/social-share">social share</a></li>
                                            <li><a href="/gallery">gallery</a></li>
                                            <li><a href="/team">team</a></li>
                                            <li><a href="/pricing-box">pricing box</a></li>
                                            <li><a href="/tabs">tabs</a></li>
                                            <li><a href="/contact">contact</a></li>
                                            <li><a href="/testimonial">testimonial</a></li>
                                            <li><a href="/countdown">countdown</a></li>
                                        </ul> 
                                         */} 
                                    </li>
                                    <li className="has-dropdown"><a href="#advantages"><span>Advantages</span></a>
                                    </li>
                                    <li className="has-dropdown"><a href="#platforms"><span>Platforms</span></a>
                                        {/*}
                                        <ul className="submenu">
                                            <li><a href="/blog-classic">blog classic</a></li>
                                            <li><a href="/blog-two-column">blog two column</a></li>
                                        </ul>
                                        */}
                                    </li>
                                    {/*<li className="has-dropdown"><a href="#pricing"><span>Pricing</span></a>
                                        <ul className="submenu">
                                            <li><a href="/portfolio-grid">portfolio grid</a></li>
                                            <li><a href="/portfolio-grid-wide">portfolio grid wide</a></li>
                                            <li><a href="/portfolio-metro">portfolio metro</a></li>
                                            <li><a href="/portfolio-grid-caption">portfolio grid caption</a></li>
                                            <li><a href="/portfolio-custom-image">portfolio custom image</a></li>
                                            <li><a href="/portfolio-details">portfolio details</a></li>
                                        </ul>
                                       
                                    </li> */}
                                    {/*
                                    <li className="has-dropdown"><a href="#enterprise"><span>Enterprise</span></a>
                                        <ul className="submenu">
                                            <li><a href="/contact">Contact Us</a></li>
                                            <li><a href="/not-found">404 Page</a></li>
                                            <li><a href="/maintenance">Maintenance</a></li>
                                        </ul>
                                    
                                    </li>*/}
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            {/*<div className="header-links d-none d-xl-block">
                                <a className="links" href="/">Try Aeroland</a>  
                                </div>*/}
                            <div className="header-button d-none d-lg-block">
                                <button className="ar-button style-white"><span className="button-text">Get Started</span></button>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default withTranslate(HeaderOne);