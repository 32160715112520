import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import ProgressOne from "./component/progressbar/ProgressOne";
import ProgressTwo from "./component/progressbar/ProgressTwo";
import ProgressThree from "./component/progressbar/ProgressThree";
import ProgressFour from "./component/progressbar/ProgressFour";
import ProgressFive from "./component/progressbar/ProgressFive";

class ProgressBar extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>ProgressBar || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
               <Breadcrumb title={'Progress Bar'} />
                {/* End Breadcrumb Area */}

                {/* Start Progress Bar Area */}
                <div className="progressbar-area pt--120 pb--90 bg-color" data-bg-color="#ffffff">
                    <div className="container">
                        <div className="row">
                            
                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressTwo />
                            </div>
                            {/* End Single Progress */}

                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressOne />
                            </div>
                            {/* End Single Progress */}

                        </div>
                    </div>
                </div>
                {/* End Progress Bar Area */}

                {/* Start Progress Bar Area */}
                <div className="progressbar-area pb--120 bg-color" data-bg-color="#ffffff">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressThree />
                            </div>
                            {/* End Single Progress */}
                            
                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressFour />
                            </div>
                            {/* End Single Progress */}
                        </div>
                    </div>
                </div>
                {/* End Progress Bar Area */}

                {/* Start Progress Bar Area */}
                <div className="progressbar-area pb--120 bg-color" data-bg-color="#ffffff">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressFive />
                            </div>
                            {/* End Single Progress */}
                            
                            {/* Start Single Progress */}
                            <div className="col-lg-6">
                                <ProgressFive />
                            </div>
                            {/* End Single Progress */}
                        </div>
                    </div>
                </div>
                {/* End Progress Bar Area */}





            </div>
        )
    }
}


export default ProgressBar;
