import React, { Component } from "react";

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class Funfacts extends Component {
    
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 598,
                countTitle: 'Successfully served',
                subtitle: 'Happy clients'
            },

            {
                countNum : 128,
                countTitle: 'Successfully completed',
                subtitle: 'Branding projects'
            },

            {
                countNum : 114,
                countTitle: 'Recruit more than',
                subtitle: 'Professional experts'
            },

            {
                countNum : 109,
                countTitle: 'Published widely',
                subtitle: 'Blog posts'
            }
        ];
        let DataList = Data.map((val, i)=>{
            return(
                <div className="counterup_style--1 col-md-3 col-6" key={i}>
                    <h2 className="title">{val.countTitle}</h2>
                    <h2 className="counter">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                        </VisibilitySensor>
                    </h2>
                    <p>{val.subtitle}</p>
                </div>
            )
        });
        return(
            <div className="funfact-area ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40 mb_sm--10">
                                <h3 className="heading">No stories, facts only</h3>
                                <p>Better software promotes overall sales & net profits</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--40 mt_sm--20">
                        {DataList}
                    </div>
                </div>
            </div>
        )
    }
}
export default Funfacts;
