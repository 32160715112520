import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from '../../accordion/Accordion'


class Marketing extends Component {
    render(){
        return(
            <div className="marketing-initiatives-area">
                <div className="marketing-wrapper">
                    <Row className="align-items-center">
                        <Col lg="6" xs="12">
                            <div className="marketing-inner">
                                <div className="section-title text-left mb--60 mb_sm--30 md_md--30">
                                    <h3 className="heading">Carry out Marketing Initiatives</h3>
                                </div>
                                <Accordion />
                            </div>
                        </Col>
                        <Col lg="6" xs="12">
                            <div className="marketing-thumbnail">
                                <div className="thumbnail">
                                    <img src="/assets/images/aeroland/aeroland-sass-image-01.png" alt="Marketing"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Marketing;
