import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";

class Dividers extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Dividers || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Dividers'} />
                {/* End Breadcrumb Area */}

                {/* Start Dividers Area */}
                <div className="dividers-area">

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner">
                                        <h5>Modern Dots</h5>
                                        <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                    </div>
                                    <div className="dividers-dots text-center pt--50">
                                        <div className="basic-modern-dots">
                                            <div className="dot first-circle"></div>
                                            <div className="dot second-circle"></div>
                                            <div className="dot third-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper">
                        <div className="container">
                            <div className="thine-line pb--70">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h5>Thin Line</h5>
                                            <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper ">
                        <div className="container">
                            <div className="ptb--70 dash-line">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h5>Dash Line</h5>
                                            <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper ">
                        <div className="container">
                            <div className="ptb--70 thick-line">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h5>Thick Line</h5>
                                            <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper ">
                        <div className="container">
                            <div className="ptb--70">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h5>Thin Short Line</h5>
                                            <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                            <div className="ar-separator text-center mt--50">
                                                <div className="thin-short-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}

                    {/* Start Single Dividers Area */}
                    <div className="ar-deviders-wrapper ">
                        <div className="container">
                            <div className="ptb--70">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h5>Thick Short Line</h5>
                                            <p>Aeroland presents your services with flexible, convenient and multipurpose layouts. You can select your favorite layouts & elements for particular projects with unlimited customization possibilities. Pixel-perfect replication of the designers is intended for both front-end & back-end developers to build their pages with greater comfort thanks to the higher customizability, adaptability as well as flexibility.</p>
                                            <div className="ar-separator text-center mt--50">
                                                <div className="thick-short-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Dividers Area */}


                </div>
                {/* End Dividers Area */}
            </div>
        )
    }
}
export default Dividers;
