import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet'
import LogoImage from "../components/header/LogoImage";
import Countdown from 'react-countdown-now';



class Maintenance extends Component{
    constructor (props) {
        super (props)
        this.state = {
            emailValue: '',
        };
    }
    render(){
        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              // Render a completed state
              return <span>You Work is Well</span>
            } else {
              // Render a countdown
              return <span className="time-counterwrapper">
                    <span className="time-inner">{days}<span className="text">Days</span></span>
                    <span className="time-inner">{hours}<span className="text">Hours</span></span>
                    <span className="time-inner">{minutes}<span className="text">Minutes</span></span>
                    <span className="time-inner">{seconds}<span className="text">Seconds</span></span>
                </span>;
            }
        };

        return(
            <Fragment>
                <Helmet>
                    <title>Maintenance || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                <div className="maintenance-area">
                    <div className="maintenane-inner">
                        <div className="row h-100 align-items-center">
                            <div className="col-lg-6 left-bg h-100">

                            </div>
                            <div className="col-lg-6 right-bg">
                                <div className="inner">
                                    <div className="content">
                                        <div className="aeroland-logo">
                                            <LogoImage logo="logo.png" />
                                        </div>
                                        <h2 className="title">Something really good is coming very soon!</h2>
                                        
                                        <div className="countdown-style--1">
                                            <Countdown date={new Date('2020')} renderer={renderer} />
                                        </div>

                                        <div className="maintanence-subscribe mt--70 mt_sm--30">
                                            <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="POST" noValidate>
                                                <label className="form-item" htmlFor='AERO2'>
                                                    <input 
                                                        type="email" 
                                                        name="EMAIL" 
                                                        id="AERO2"
                                                        value={this.state.emailValue} 
                                                        onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
                                                        autoCapitalize="off" 
                                                        autoCorrect="off"
                                                        placeholder="Email"
                                                    /> 
                                                </label>
                                                <div className="subscribe-button mt--35">
                                                    <button className="ar-button style-flat" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">
                                                        <span className="button-text">Subscribe</span>
                                                    </button>
                                                </div>
                                                
                                            </form>
                                        </div> 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Maintenance;