import React, { Component } from "react";
import ModalVideo from 'react-modal-video'
import BusinessFeature from "../../feature/Feature";
import { FaPlayCircle } from "react-icons/fa";

class BusinessAnalitcs extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="business-analitics-area pb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--30">
                                <h3 className="heading mb--10 fontWeight500">Receive real-time business analytics</h3>
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                <button className="video-button" onClick={this.openModal}><FaPlayCircle /><span>Intro & demo video</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-12 text-center text-md-left pr--70 plr_md--30 plr_sm--30">
                        <div className="thumbnail">
                            <img src="/assets/images/mokup/aeroland-macbook-03.png" alt="App Landing"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 col-xl-6">
                        <div className="text-center feature-wrapper">
                            <BusinessFeature />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BusinessAnalitcs;
