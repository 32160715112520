import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";

class MessageBox extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Message box || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Message box'} />
                {/* End Breadcrumb Area */}

                {/* Start Message Box Area */}
                <div className="message-box-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="message-box mb--30">
                                    <div className="icon">
                                        <i className="fa fa-info-circle"></i>
                                    </div>
                                    <p>This is a informational message</p>
                                </div>

                                <div className="message-box success mb--30">
                                    <div className="icon">
                                        <i className="fa fa-info-circle"></i>
                                    </div>
                                    <p>This is a success message</p>
                                </div>

                                <div className="message-box danger mb--30">
                                    <div className="icon">
                                        <i className="fa fa-info-circle"></i>
                                    </div>
                                    <p>This is a danger message</p>
                                </div>

                                <div className="message-box warning">
                                    <div className="icon">
                                        <i className="fa fa-info-circle"></i>
                                    </div>
                                    <p>This is a warning message</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Message Box Area */}


            </div>
        )
    }
}
export default MessageBox;
