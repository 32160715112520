import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import HeaderOne from '../components/header/HeaderOne';
import BoxIconFive from '../elements/component/box-icon/BoxIconFive';
import ListOne from '../elements/component/list/ListOne';
import PricingBoxThree from '../elements/component/pricingBox/PricingBoxThree';
import ContactThree from '../elements/component/contact/ContactThree';
import Footer from '../components/footer/Footer';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class Payment extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 1760,
                countTitle: 'PAYMENT ACCOUNTS',
            },
            {
                countNum : 2794,
                countTitle: 'TOTAL DOWNLOADS',
            },
        ];
        let DataList = Data.map((val, i)=>{
            return(
                <div className="counterup_style--4 col-md-6 col-12 mt--30" key={i}>
                    <h2 className="counter">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                        </VisibilitySensor>
                    </h2>
                    <h2 className="title">{val.countTitle}</h2>
                </div>
            )
        });
        return(
            <React.Fragment>
                <Helmet>
                    <title>Payment || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--payment bg_image bg_image--7">
                    {/* Start Single Slide */}
                    <div className="slider-text-wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <div className="slider-content">
                                    <div className="inner">
                                        <h1 className="text-white title">The world's top secure payment platform</h1>
                                        <p className="text-white font--18 description">There’s no need to be a technical professional to create sites with AeroLand. With simple guides, everything is easy!</p>
                                        <button className="ar-button style-flat secondary" type="submit">
                                            <span className="button-text">Get started</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-thumbnail">
                            <img src="/assets/images/bg/aeroland-payment-hero-image.png" alt="App Landing"/>
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area */} 

                {/* Start payment Service Area */}
                <div className="payment-icon-service-area mt--100 mt_md--0 mt_lg--0 mt_sm--0">
                    <div className="payment-service-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Advantages AeroLand Brings</h2>
                                        <p>You’ll need these advantages to take the lead.</p>
                                    </div>
                                </div>
                            </div>
                            <BoxIconFive />
                        </div>

                        <div className="vc_row-separator tilt_left bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 4 0.266661">
                                <linearGradient id="svg-fill-5d2a97d43e7aa">
                                    <stop offset="0%" stopColor="#5e61e7"></stop>
                                    <stop offset="100%" stopColor="#9c7af2"></stop>
                                </linearGradient>
                                <polygon points="4,0 4,0.266661 -0,0.266661" fill="url( #svg-fill-5d2a97d43e7aa )"></polygon>
                            </svg>
                        </div>
                    </div>
                    <div className="online-credit-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h2 className="text-white mb--5">Say hello to Auto Process.</h2>
                                        <p className="text-white mb--0">Financial transactions turn from physical to digital.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-separator tilt_right top">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 4 0.266661">
                                <linearGradient id="svg-fill-5d2adb57950b9">
                                    <stop offset="0%" stopColor="#9c7af2"></stop>
                                    <stop offset="100%" stopColor="#5e61e7"></stop>
                                </linearGradient>
                                <polygon points="4,0 4,0.266661 -0,0.266661" fill="url( #svg-fill-5d2adb57950b9 )"></polygon>
                            </svg>
                        </div>
                    </div>
                    <div className="section-separator">
                        <div className="separator--1"></div>
                        <div className="effortless-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="thumbnail">
                                            <img src="/assets/images/aeroland/payment-image-01.png" alt="Thumbnail"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="effortless-content">
                                            <div className="inner">
                                                <div className="space--1"></div>
                                                <h2>Effortless billing with AeroLand <sup>01</sup></h2>
                                                <p className="mb--50">Enhanced billing systems offer you a variety of ways to pay electric bills, phone bills, medical bills delivered online, in person or by mail.</p>

                                                <button className="ar-button style-flat with-left" type="submit">
                                                    <span className="button-text"><i className="fa fa-download"></i> Try for free</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End payment Service Area */}

                {/* Start Secure Payment Area */}
                <div className="secure-payment-area ptb--120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="secure-content">
                                    <div className="inner mb--55 mb_sm--20 mb_md--20">
                                        <h3 className="payment-heading">Secure payment <sup>02</sup></h3>
                                    </div>
                                    <ListOne />

                                    <div className="sucure-btn mt--40">
                                        <button className="ar-button style-flat with-icon" type="submit">
                                            <span className="button-text">Standard Style <i className="fa fa-arrow-right"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 mt_sm--30">
                                <div className="thumbnail text-right">
                                    <img src="/assets/images/aeroland/payment-image-02.png" alt="app landing"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Secure Payment Area */}

                {/* Start Payment System Area */}
                <div className="payment-system-area payment-sestem-bg-image">
                    <div className="for-bottom-shape">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="thumbnail">
                                        <img src="/assets/images/aeroland/payment-image-03.png" alt="App Landing"/>
                                    </div>
                                </div>
                                <div className="col-lg-5 mt_sm--30">
                                    <div className="content">
                                        <div className="inner">
                                            <h3 className="payment-heading sup-right mb--45 mb_sm--20">Payment system access <sup>03</sup></h3>
                                            <p className="font--18 mb--15 mb_sm--0">The access to payment account is conducted through 2-step authentication…</p>
                                        </div>
                                        <div className="row">
                                            {DataList}
                                        </div>
                                        <div className="button-group mt--60 mt_sm--20 mt_md--20 mt_lg--20">
                                            <button className="button-image" type="submit">
                                                <img src="/assets/images/logo/app-store-dark.jpg" alt="Logo Images" />
                                            </button>
                                            <button className="button-image" type="submit">
                                                <img src="/assets/images/logo/google-play-dark.jpg" alt="Logo Images" />
                                            </button>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vc_row-separator tilt_left bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 4 0.266661">				<linearGradient id="svg-fill-5d2bf6d1183e8">
                                <stop offset="0%" stopColor="#8481ec"></stop>
                                <stop offset="100%" stopColor="#a890f2"></stop>
                                </linearGradient>
                                <polygon points="4,0 4,0.266661 -0,0.266661" fill="url( #svg-fill-5d2bf6d1183e8 )"></polygon>
                            </svg>
                        </div>
                    </div>
                    {/* Start Pricing Table Area */}
                    <div className="pricing-table-area payment-pricig-table">
                        <div className="pricing-bg-shape">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center">
                                            <h2 className="text-white">Affordable for everyone!</h2>
                                            <p className="text-white">Choose a suitable package for your site</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-bg-svg">
                            <div className="vc_row-separator tilt_right top">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 4 0.266661">				        <linearGradient id="svg-fill-5d2bf6d11a7a6">
                                        <stop offset="0%" stopColor="#a890f2"></stop>
                                        <stop offset="100%" stopColor="#8481ec"></stop>
                                    </linearGradient>
                                    <polygon points="4,0 4,0.266661 -0,0.266661" fill="url( #svg-fill-5d2bf6d11a7a6 )"></polygon>
                                </svg>
                            </div>
                            <div className="container">
                                <PricingBoxThree />
                            </div>
                        </div>
                    </div>
                    {/* End Pricing Table Area */}
                </div>
                {/* End Payment System Area */}
                <ContactThree />
                
                {/* Start Footer Area */}
                <Footer />
                {/* End Footer Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </React.Fragment>    
        )
    }
}
export default Payment;