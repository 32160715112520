import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from "prop-types";
import ListOne from "../list/ListOne";

//random comment
class TabsOne extends Component{
    render(){
        let 
        tab1 = "Advantages", 
        tab2 = "Our mission",
        tab3 = "Friendly Support";

        const { historyTitle , missionTitle ,missionDescription} = this.props
        
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className="tab-style--1">
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="col-lg-7">
                                                <div className="thumbnail">
                                                    <img src="/assets/images/aeroland/tab-content-image-01.png" alt="app landing"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 mt_md--30 mt_sm--30">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">
                                                        <p className="mb--40 mb_sm--20">{historyTitle}</p>
                                                        <ListOne />
                                                        <div className="tab-button mt--40 mt_sm--20">
                                                            <button className="ar-button style-flat" type="submit">
                                                                <span className="button-text">Learn more</span>
                                                            </button>
                                                        </div>
                                                        <h5 className="color-3 mt--30 mb--0">Next step in IT Consumption. <a className="link-hover color-4" href="/">Learn more</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    
                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="col-lg-7">
                                                <div className="thumbnail">
                                                    <img src="/assets/images/aeroland/tab-content-image-02.png" alt="app landing"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 mt_md--30 mt_sm--30">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">
                                                        <h3 className="mb--40 mb_sm--20 fontWeight400 font--34">{missionTitle}</h3>
                                                        <p>{missionDescription}</p>
                                                        <div className="tab-button mt--40 mt_sm--20">
                                                            <button className="ar-button style-flat" type="submit">
                                                                <span className="button-text">Learn more</span>
                                                            </button>
                                                        </div>
                                                        <h5 className="color-3 mt--30 mt_sm--20 mb--0">Top of the line cloud virtualization? <a className="link-hover color-4" href="/">Learn more</a></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="thumbnail">
                                                    <img src="/assets/images/aeroland/tab-content-image-03.png" alt="app landing"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">

                                                        {/* Start Single Box */}
                                                        <div className="secret-link">
                                                            <div className="secret-link-box">
                                                                <div className="image">
                                                                    <img src="/assets/images/aeroland/tab-content-image-04.png" alt="app landing"/>
                                                                </div>
                                                                <div className="content">
                                                                    <h6>Hotline</h6>
                                                                    <h5>+91 84 24 848484</h5>
                                                                    <p>Business hours phone support</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End Single Box */}

                                                        {/* Start Single Box */}
                                                        <div className="secret-link mt--50 mt_sm--20">
                                                            <div className="secret-link-box">
                                                                <div className="image">
                                                                    <img src="/assets/images/aeroland/tab-content-image-05.png" alt="app landing"/>
                                                                </div>
                                                                <div className="content">
                                                                    <h6>Leave your Feedback</h6>
                                                                    <h4>howdy@cloudtantra.io</h4>
                                                                    <p>24/7 email support</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End Single Box */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}

//Props Types
TabsOne.propTypes = {
    historyTitle: PropTypes.string,
    missionTitle: PropTypes.string,
    missionDescription: PropTypes.string,
};

//Default Props
TabsOne.defaultProps = {
    historyTitle: "We enable access of information on current infrastructures using remote virtual computers.",
    missionTitle: "Cloud first IT delivery",
    missionDescription: "We have pre packaged common configurations into ready to deploy templates. In most cases we can deploy entire office environments, development workflows in a matter of minutes. Even in outlier cases we handle all the configuration and manage all the systems."
    
};

export default TabsOne;