import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CountersFour extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    
    
    render(){
        let Data = [
            {
                countNum : 1760,
                countTitle: 'ACCOUNTS CREATED',
            },
            {
                countNum : 2794,
                countTitle: 'TIMES DOWLOADED',
            },
            {
                countNum : 1940,
                countTitle: 'CLIENTS SATISFIED',
            },
            {
                countNum : 2017,
                countTitle: 'PROJECTS FINISHED',
            }
        ];
        let DataList = Data.map((val, i)=>{
            return(
                <div className="counterup_style--4 col-md-3 col-6" key={i}>
                    <h2 className="counter">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                        </VisibilitySensor>
                    </h2>
                    <h2 className="title">{val.countTitle}</h2>
                </div>
            )
        });
        return (
            <div className="row mt--40 mt_sm--5">
                {DataList}
            </div>
        )
    }
}
export default CountersFour;