import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import HeaderOne from '../components/header/HeaderOne';
import { FaPlayCircle } from "react-icons/fa";
import ModalVideo from 'react-modal-video'
import BoxIconFour from "../elements/component/box-icon/BoxIconFour";
import BoxIconFive from "../elements/component/box-icon/BoxIconFive";
import BoxIconTwo from "../elements/component/box-icon/BoxIconTwo";
import PricingBoxOne from "../elements/component/pricingBox/PricingBoxOne";
import TabsOne from "../elements/component/tabs/TabsOne";
import CalltoActionOne from "../elements/component/callto-action/CalltoActionOne";
//import TeamTwo from "../elements/component/team/TeamTwo";
import TabsThree from "../elements/component/tabs/TabsThree";
import CalltoActionFour from "../elements/component/callto-action/CalltoActionFour";
import Wavify from '../components/plugins/WavifyWhite';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Footer from '../components/footer/Footer';

class StartUps extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Cloud Desk|| Fully managed IT systems in the cloud.</title>
                    <meta name="description" content="Fast, Flexible cloud desktop access fully redundant, high performance cloud desktops. You benefit from its high reliability, security and enterprise feature set." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area">
                    {/* Start Single Slide */}
                    <div className="single-slide slider--startups bg_image fullscreen bg_image--5">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="slider-content" >
                                     
                                    <div className="inner" style={{marginTop: '0px'}}>
                                        <h2 className="text-white title">Fast, Secure, Managed  Desktop-as-a-service</h2>
                                        <p className="text-white font--18 description">with power of a workstation and the flexibility of the cloud. No more physical servers, wiring, RAM sticks, hard disks, network appliances to manage. We build, patch, maintain and optimize your infrastructure with 100% redundancy.</p>

                                        <p className="text-white font--18 description">Each user receives their own desktop with consistent performance across sessions, no matter how many users are logged on at the same time. Transition to 100% BYOD.</p>

                                        <div className="button-group d-block d-sm-flex align-items-center">
                                            <button className="ar-button style-flat secondary" type="submit">
                                                <span className="button-text">Get Started</span>
                                            </button>
                                            <div className="aro-btn-second d-flex align-items-center">
                                                <h6 className="text-white mb--0">Questions?</h6> 
                                                <a className="link-hover color-4" href="#contact"> Talk to our team</a>
                                            </div>
                                        </div>
                                        

                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail text-left">
                                    <img src="/assets/images/slider/startup-hero-image-reup.png" alt="Slider Images"/>
                                </div>
                            </div>
                        </div>
                        <div className="wavify-position">
                            <Wavify />
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area */}
{/* Start Startup TWO Service */}
<div id="advantages" className="startups-service pt--80 pb--120 bg_color--1">
                    <div className="container">
                       
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--45">
                                    <br/>
                                    <h2>Immediate Advantages</h2>
                                    <p><span>Designed for knowledge workers, designers, developers, hackers and makers.</span></p>
                                </div>
                            </div>
                        </div>
                         
                        <BoxIconFive/>
                        
                         
                    </div>
                </div>
                {/* End Startup TWO Service */}
                {/* Start Startup Service */}
                <div id="features" className="startups-service pt--80 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20">
                                    <h2>We’ve created turnkey systems for <br /> startups and enterprises</h2>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-button" onClick={this.openModal}><FaPlayCircle /><span>Intro & demo video</span></button>
                                </div>
                            </div>
                        </div>
                        <BoxIconFour />
                         
                        
                        <div className="row mt--70 text-center">
                            <div className="col-lg-12">
                                <button className="ar-button style-flat with-icon" type="submit">
                                    <span className="button-text">Onboard your app development team in 5 minutes.<i className="fa fa-arrow-right"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Startup Service */}
 

                {/* Start Platform Area */}
                <div id="platforms" className="present-platform-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--45">
                                    <h2>Over 50 optimized platforms</h2>
                                    <p>Our optimized systems are tunkey and designed to work together</p>
                                </div>
                            </div>
                        </div>
                        <BoxIconTwo />
                    </div>
                </div>
                {/* End Platform Area */}


                {/* Start Startups tabs Area */}
                <div className="startups-tabs-area startups-bg-images ptb--120 bg_color--1">
                    <CalltoActionOne />
                    <div className="container pt--120">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--60">
                                    <h2>Setup, iterate, optimize  <br /> faster and faster.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabsOne />
                </div>
                {/* End Startups tabs Area */}


                {/* Start Team Area */}
                <div id="pricing" className="pricing-table-area bg-color ptb--120">
                    {/*<TeamTwo /> */}
                    {/*<PricingBoxOne/>*/}

                </div>
                {/* End Team Area */}

                {/* Start Tabs Area */}
                <div id="enterprise" className="our-tabs-area startups-tabstwo-area pt--120 pb--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--80 mb_lg--30 mb_sm--30 md_md--30">
                                    <h2>Designed for Scale and  Growth</h2>
                                    <p>Scale your business, not costs. Run your environment and apps on our infrastructure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabsThree />
                </div>
                {/* End Tabs Area */}

                 {/* Start Call TO Action */}
                 <div className="call-to-action-wrapper call-to-action-four-bg theme-gradient--2">
                    <CalltoActionFour />
                </div>
                {/* End Call TO Action */}
                <Footer />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </React.Fragment>
        )
    }
}


export default StartUps;
