import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import ModalVideo from 'react-modal-video'

class PopupVideo extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div>
                <Helmet>
                    <title>PopupVideo || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

               {/* Start Breadcrumb Area */}
               <Breadcrumb title={'Popup Video'} />
                {/* End Breadcrumb Area */}

                {/* Start Popup Video Area */}
                <div className="popup-video ptb--120 thine-line">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Popup */}
                            <div className="col-lg-6">
                                <div className="popup-video-inner position-relative">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/others/video.jpg" alt="Popup Images"/>
                                    </div>
                                    <div className="icon-position">
                                        <div className="popup-video">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Popup */}

                            {/* Start Single Popup */}
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="popup-video-inner position-relative">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/others/video.jpg" alt="Popup Images"/>
                                    </div>
                                    <div className="icon-position">
                                        <div className="popup-video">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup icon-large" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Popup */}
                        </div>
                    </div>
                </div>
                {/* End Popup Video Area */}

                {/* Start Popup Video Area */}
                <div className="popup-video thine-line ptb--120">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Popup */}
                            <div className="col-lg-6">
                                <div className="popup-video-inner position-relative">
                                    <div className="thumbnail">
                                        <img  className="w-100" src="/assets/images/others/video.jpg" alt="Popup Images"/>
                                    </div>
                                    <div className="icon-position">
                                        <div className="popup-video">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup button-solid" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Popup */}

                            {/* Start Single Popup */}
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="popup-video-inner position-relative">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/others/video.jpg" alt="Popup Images"/>
                                    </div>
                                    <div className="icon-position">
                                        <div className="popup-video">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup button-solid-blue button-right" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            {/* End Single Popup */}

                        </div>
                    </div>
                </div>
                {/* End Popup Video Area */}

                {/* Start Popup Video Area */}
                <div className="popup-video thine-line ptb--120">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Popup */}
                            <div className="col-lg-6">
                                <div className="popup-video-inner position-relative">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/others/video.jpg" alt="Popup Images"/>
                                    </div>
                                    
                                    <div className="icon-position">
                                        <div className="popup-video">
                                            <div className="video-marker">
                                                <div className="wave-pulse wave-pulse-1"></div>
                                                <div className="wave-pulse wave-pulse-2"></div>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup button-solid-yellow" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Popup */}
                        </div>
                    </div>
                </div>
                {/* End Popup Video Area */}


            </div>
        )
    }
}
export default PopupVideo;