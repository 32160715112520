import React, { Component } from "react";



class CircleBox extends Component{
    render(){
        return(
            <div className="innoviatives-ideas-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="circle-box">
                                <div className="inner">
                                    <div className="countinner">
                                        <h2 className="number-position">
                                            <span className="number">{this.props.number}</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="circle-design one"></div>
                                <div className="circle-design two"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CircleBox;












