import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class BoxIconFour extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Web App Design & Development',
                desc: 'Accelerate your project by using our preconfigured LAMP, LAPP, MEAN, PERN, JAVA stacks. All stacks are available in development & production configurations.'
            },

            {
                icon: '02',
                title: 'Mobile App Development',
                desc: 'Develop on android studio, test on superfast emulators. Test on actual devices at scale. Pick and choose your test hardware, only pay for what you use.'
            },

            {
                icon: '03',
                title: 'Ideal for Knowledge Work',
                desc: 'Fast computers with superfast internet for knowledge workers. Preconfigured with web browser, office suite, email client and a desktop publishing system.'
            },

            {
                icon: '04',
                title: 'Managed DevOps and CI/CD',
                desc: 'Our systems securely store and version your application`s source code and automatically build, test and can deploy your application to production.'
            },

            {
                icon: '05',
                title: 'Production Ready Components',
                desc: 'Highly available and fault tolerant Web & database servers are tied directly to our infrastructure. NGINX, TOMCAT, APACHE, Aurora, Cassandra, Redis to name a few.'
            },

            {
                icon: '06',
                title: 'Enterprise Advantage',
                desc: 'Very High speed internet & scalable fault tolerant storage. 100% redundancy with deep monitoring of network infrastructure. Secure by default configuration.'
            }
            
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col lg="4" md="6" sm="6" xs="12" key={i}>
                    <div className="box-icon--4">
                        <div className="icon">
                            <img src={`/assets/images/box-img/startup-box-icon-${val.icon}.png`} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}

export default BoxIconFour;

