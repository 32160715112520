import React , { Component } from "react";
import {Helmet} from 'react-helmet'
import HeaderOne from '../components/header/HeaderOne';
import Brand from '../components/brand/Brand';
import IdeasApp from '../components/layouts/appshowcase/IdeasApp';
import DownloadNow from '../components/layouts/appshowcase/DownloadNow';
import AllAppShowcase from '../components/layouts/appshowcase/AllAppShowcase';
import Newsletter from '../components/layouts/appshowcase/Newsletter';
import FooterTwo from '../components/footer/FooterTwo';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class AppShowcase extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>AppShowcase | Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                 {/* Start Header Area */}
                 <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--appshowcase fullscreen bg_image bg_image--14">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="slider-content pt--160 pt_md--50 pt_sm--50 text-center">
                                    <div className="inner">
                                        <h2 className="text-white title fontWeight400">Free App Download Now</h2>
                                        <p className="text-white description">Responsive app for all kinds of purposes.</p>
                                        <div className="button-group">
                                            <button className="button-image" type="submit">
                                                <img src="/assets/images/mokup/aeroland-button-app-store.jpg" alt="Logo Images" />
                                            </button>
                                            <button className="button-image" type="submit">
                                                <img src="/assets/images/mokup/aeroland-button-google-play.jpg" alt="Logo Images" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="thumbnail">
                                        <img src="/assets/images/mokup/showcase-hero-thumb.png" alt="Slider Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="svg-separator">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
                            <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
                        </svg>
                    </div>
                </div>
                {/* End Slider Area */}

                {/* Start Brand Area */}
                <Brand />
                {/* End Brand Area */}

                <IdeasApp />

                <DownloadNow />

                <AllAppShowcase />

                <Newsletter />

                 {/* Start Back To Top */}
                 <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

            </div>
        )
    }
}


export default AppShowcase;
