import React, { Component } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";


class GalleryOne extends Component{
    render(){
        const Gallery = [
            "gallery-big-01.jpg",
            "gallery-big-02.jpg",
            "gallery-big-03.jpg",
            "gallery-big-04.jpg",
            "gallery-big-05.jpg",
            "gallery-big-06.jpg",
        ];
        
        const PhotoItem = ({ image, group }) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="gallery">
                    <LightgalleryItem group={group} src={`/assets/images/gallery/${image}`}>
                        <img className="w-100" src={`/assets/images/gallery/${image}`} alt="Aeroland Images" />
                        <div className="action">
                            <i className="fa fa-search"></i>
                        </div>
                        <div className="overlay"></div>
                    </LightgalleryItem>
                </div>
            </div>
        );

        return(
            <div>
                <LightgalleryProvider>
                    <div className="row">
                        {Gallery.map((p, idx) => (
                            <PhotoItem key={idx} image={p} group="group1" />
                        ))}
                    </div>
                   
                </LightgalleryProvider>
            </div>
        )
    }
}
export default GalleryOne;
