import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Portfolio from "./components/Portfolio";

class PortfolioGrid extends Component{
    render(){
        return(
            <Fragment>

                <Helmet>
                    <title>PORTFOLIO GRID || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'PORTFOLIO GRID'} />
                {/* End Breadcrumb Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120">
                    <div className="container">
                        <Portfolio SingleColumn="col-lg-4 col-sm-6 col-md-6 col-12" startItem="0" col="9" PortfolioStyle="portfolio" />
                    </div>
                </div>
                {/* End Portfolio Area */}

            </Fragment>
        )
    }
}

export default PortfolioGrid;