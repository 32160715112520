import React, { Component } from "react";

class CalltoActionOne extends Component{
    render(){
        return(
            <div className="container">
                <div className="row row--0 call-to-action--1">
                    <div className="col-lg-6">
                        <div className="inner ptb--50">
                            <div className="content">
                                <h3 className="text-white">Hardware free business
                                </h3>
                                <h5 className="text-white">Offer your business with the best assistance for growth.</h5>
                                <a className="download-btn" href="/">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                                    </svg>
                                    <span>Get AeroLand</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img src="/assets/images/call-to-action/call-to-action-01-macbook.png" alt="App Landing" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CalltoActionOne;