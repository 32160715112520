import React, { Component } from "react";
import { FaCheck } from "react-icons/fa";


class MeetingSeo extends Component {
    render(){
        return(
            <div className="meeting-seo-area bg_color--4">
                <div className="meeting-seo-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="seo-content">
                                <div className="section-title text-left mb--25">
                                    <h3 className="heading mb--30 fontWeight600">Meeting all of your requirements for SEO</h3>
                                    <p>AeroLand creates a prominent advantage for your business with strong features.</p>
                                </div>
                                <div className="inner">
                                    <ul className="list">
                                        <li><FaCheck /> <span>Customer First Content-focused Displaying Effects</span></li>
                                        <li><FaCheck /> <span>High Definition Full-screen Sliders & Backgrounds</span></li>
                                        <li><FaCheck /> <span>Fast Loading, Reader Friendly Navigation Style</span></li>
                                    </ul>
                                    <button className="ar-button style-flat mt--45" type="submit"><span className="button-text">Learn More</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="thumbnail">
                                <img src="/assets/images/mokup/aeroland-branding-image-laptop.png" alt="Laptop images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MeetingSeo;
