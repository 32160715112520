import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconFive extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Scale without Infrastructure',
                desc: 'Fully managed with pay-as-you-go pricing. You can easily scale to any number of users across the globe without acquiring, provisioning, and operating hardware or infrastructure.'
            },

            {
                icon: '02',
                title: 'Deliver Applications to any Computer',
                desc: 'Users can access the desktop applications they need at any time. Cloud Desk streams your applications from the datacenter to any computer, Chromebooks, Macs or PCs.'
            },

            {
                icon: '03',
                title: 'Secure applications and Data',
                desc: 'Applications and data are not stored on users computers. Your applications and access data secured within your network. You benefit from a data center and network architecture built for the most security-sensitive organizations.'
            },

            {
                icon: '04',
                title: 'Fast, smooth and fluid experience',
                desc: 'Each users applications run on VMs optimized for their use cases. The systems automatically adjusts each streaming session to network conditions for a fluid user experience.'
            },

            {
                icon: '05',
                title: 'Centralized Management',
                desc: 'Each user accesses the same version of your applications and operating system. You centrally manage your applications and can stop managing installations and updates on each users computer.'
            },

            {
                icon: '06',
                title: 'From Zero to Ready in 70 seconds',
                desc: 'Automated release process, allows you to rapidly release new features. Automated build, test, release process allows you to quickly and easily test each code change & catch bugs.'
            }
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col className="mt--30" lg="6" md="6" sm="12" xs="12" key={i}>
                    <div className="box-icon--5">
                        <div className="icon">
                            <img src={`/assets/images/box-img/payment-box-icon-${val.icon}.png`} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row>
                {DataList}
            </Row>
        )
    }
}

export default BoxIconFive;