import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Iconbox extends Component{
    render(){
        let Data = [
            {
                icon: 'fa-fire',
                title: 'Define your ideas',
                desc: 'Share ideas of how you would like your app to perform to achieve goals.'
            },
            {
                icon: 'fa-users',
                title: 'Work on designs',
                desc: 'After getting your brief, our experts work on crafting the final designs.'
            },
            {
                icon: 'fa-chart-line',
                title: 'Coding and Review',
                desc: 'The final product is obtained from meticulous coding and review.'
            }
        ]
        let DataList = Data.map((val , i) => {
            return(
                <Col className="single-icon-box mt_sm--30" lg="4" md="4" sm="6" xs="12" key={i}>
                    <div className="icon-box">
                        <div className="line">
                            <div className="dot dot-1"></div>
                            <div className="dot dot-2"></div>
                            <div className="dot dot-3"></div>
                            <div className="dot dot-4"></div>
                            <div className="dot dot-5"></div>
                            <div className="dot dot-4"></div>
                            <div className="dot dot-3"></div>
                            <div className="dot dot-2"></div>
                            <div className="dot dot-1"></div>
                        </div>
                        <div className="content">
                            <div className="icon">
                                <i className= {`fa ${val.icon}`}></i>
                            </div>
                            <h5>{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>
            )
        })
        return(
            <Container>
                <Row className="mt_dec--30">
                    {DataList}
                </Row>
            </Container>
        )
    }
}
export default Iconbox