import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import GalleryOne from "./component/gallery/GalleryOne";
import Breadcrumb from "../components/common/Breadcrumb";

class Gallery extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Gallery || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Gallery'} />
                {/* End Breadcrumb Area */}
                
                {/* Start Gallery Area */}
                <div className="galery-area ptb--120 thine-line">
                    <div className="container">
                        <GalleryOne />
                    </div>
                </div>
                {/* End Gallery Area */}
            </div>
        )
    }
}
export default Gallery;
