import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class Counterup extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        return (
            <div className="row">
                {/* Start Single Counter Up */}
                <div className="col-lg-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="counterup_style--2 color--yellow">
                        <div className="icon">
                            <div className="inner">
                                <div className="countinner">
                                    <h2 className="counter">
                                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                            <CountUp end={this.state.didViewCountUp ? 576 : 0} />
                                        </VisibilitySensor>
                                    </h2>
                                </div>
                            </div>
                            <div className="circle-design one color--yellow"></div>
                            <div className="circle-design two color--yellow"></div>
                        </div>
                        <div className="content">
                            <h2 className="title">Digital Marketing</h2>
                            <p>Keyword rank tracking <br /> Website audit <br /> Marketing, SEO <br /> Social Media <br /> On-page SEO Audit</p>
                        </div>
                    </div>
                </div>
                {/* End Single Counter Up */}

                {/* Start Single Counter Up */}
                <div className="col-lg-6 col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--40">
                    <div className="counterup_style--2">
                        <div className="icon">
                            <div className="inner">
                                <div className="countinner">
                                    <h2 className="counter">
                                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                            <CountUp end={this.state.didViewCountUp ? 75 : 0} />
                                        </VisibilitySensor>
                                    </h2>
                                </div>
                            </div>
                            <div className="circle-design one"></div>
                            <div className="circle-design two"></div>
                        </div>
                        <div className="content">
                            <h2 className="title">Web Design, UI/UX</h2>
                            <p>Branding & Strategy <br /> Web Design & Development <br /> Packaging Design <br /> Video & Animation <br /> Navigation Effects</p>
                        </div>
                    </div>
                </div>
                 {/* End Single Counter Up */}
            </div>
        )
    }
}
export default Counterup;