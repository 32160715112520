import React, { Component } from "react";
import VideoButton from "../../common/VideoButton";
import BusinessFeature from "../../feature/Feature";

class SoftwareService extends Component{
    render(){
        return (
            <div className="software-service-area pb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55">
                                <h3 className="heading fontWeight400 mb--20">In-depth Sales Reports Available at <br /> Your Hand</h3>
                                <VideoButton ButtonStyle="button-transparent" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="software-service-wrapper">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="thumbnail">
                                <img src={`/assets/images/mokup/software-image-01.png`} alt="App Landing"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mt_md--30 mt_sm--30 col-12">
                            <div className="service-wrapper">
                                <BusinessFeature />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h4 className="color-3 mb--0">What’s next in AeroLand? <a className="link-hover color-4" href="/">Learn more</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}




export default SoftwareService;
