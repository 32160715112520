import React, { Component } from "react";

class SectionTitle01 extends Component{
    render(){
        return(
            <div className={`section-title ${this.props.titleAlign}`}>
                <h3 className="heading">Developed for Higher Business Growth</h3>
                <p>Offer your business with the best assistance for growth.</p>
            </div>
        )
    }
}

export default SectionTitle01;




