import React, { Component } from "react";

class FooterFour extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer footer-style--4 ptb--130 ptb_sm--60 ptb_md--80">
                    <div className="footer-top pb--80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="inner mb_sm--30 mb_md--30">
                                        <h3 className="font--40 fontWeight400">Let’s talk & start working</h3>
                                        <button className="contact-btn">Contact us <i className="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="row">
                                        {/* Start Single Contant */}
                                        <div className="col-lg-6 col-md-6">
                                            <div className="digital-agency-contant">
                                                <div className="thumbnail">
                                                    <a href={`${process.env.PUBLIC_URL}/digital-agency`}>
                                                        <img src="/assets/images/aeroland/digital-agency-contact-image-01.jpg" alt="App Landing"/>
                                                    </a>
                                                </div>
                                                <div className="heading">
                                                    <h4 className="title">San Francisco</h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Contant */}

                                        {/* Start Single Contant */}
                                        <div className="col-lg-6 col-md-6 mt_sm--30">
                                            <div className="digital-agency-contant">
                                                <div className="thumbnail">
                                                    <a href={`${process.env.PUBLIC_URL}/digital-agency`}>
                                                        <img src="/assets/images/aeroland/digital-agency-contact-image-02.jpg" alt="App Landing"/>
                                                    </a>
                                                </div>
                                                <div className="heading">
                                                    <h4 className="title">San Francisco</h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Contant */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="copyright-innner">
                                        <p>© 2020 Aeroland. <br />All Rights Reserved.</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 mt_md--30 mt_sm--30">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="footer-widget">
                                                <h3 className="title">Office</h3>
                                                <div className="inner">
                                                    <p>58 Howard Street #2 <br /> San Francisco, CA 941</p>
                                                    <p>The Courtyard Building <br /> 11 Curtain Road <br />London</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 offset-lg-1 mt_sm--30">
                                            <div className="footer-widget">
                                                <h3 className="title">Start a conversation</h3>
                                                <div className="inner">
                                                    <p className="color-4">contact@aeroland.com</p>
                                                    <p className="fontWeight700">(+68)1221 09876</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12 mt_md--30 mt_sm--30">
                                            <div className="footer-widget">
                                                <h3 className="title">Start a conversation</h3>
                                                <div className="inner">
                                                    <p><a href="//www.facebook.com">Facebook</a></p>
                                                    <p><a href="//www.instagram.com">Instagram</a></p>
                                                    <p><a href="//twitter.com">Twitter</a></p>
                                                    <p><a href="//dribbble.com">Dribbble</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default FooterFour;