import React , {Component} from "react";
import ModalVideo from 'react-modal-video'

const Workprocess = (props) => {
    let names = [
        'Design responsive modern websites',
        'Redesign or revise design of outdated websites',
        'Develop useful features and functions',
        'Check for code error and bug issues',
    ];
    return (
        <div className="work-process-inner">
            <h2 className="mb--25 mb_sm--20 mb_md--20 font--40 fontWeight400">{props.title}</h2>
            <p className="font--18 mb--15">{props.subtitle}</p>
            <ul className="list-style--1">
                {names.map((name, index) => {
                    return <li className="font--18 bodyColor" key={ index }><i className="fa fa-check"></i> {name}</li>;
                })}
            </ul>
            <h5 className="color-3 font--18 mt--55 mt_sm--20 mt_md--20">{props.nextTile} <a className="color-4 link-hover" href="/">{props.nextLink}</a></h5>
        </div>
    )
}

class HowToWork extends Component {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        let thumbnail = '/assets/images/aeroland/digital-agency-poster-image-01.jpg';
        return(
            <React.Fragment>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="image-wrapper">
                            <div className="thumbnail-inner popup-video-inner position-relative">
                                <div className="thumbnail">
                                    <img src={thumbnail} alt="App Landing"/>
                                </div>
                                <div className="icon-position">
                                    <div className="popup-video">
                                        <div className="video-marker">
                                            <div className="wave-pulse wave-pulse-1"></div>
                                            <div className="wave-pulse wave-pulse-2"></div>
                                        </div>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                        <button className="video-popup button-solid-yellow" onClick={this.openModal}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                       <Workprocess title="How it works" subtitle="We design powerful websites & apps for landing your success." nextTile="What’s next in AeroLand?" nextLink="Learn more" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default HowToWork;



