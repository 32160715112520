import React, { Component } from "react";

import Brand2 from "../../brand/Brand2";


class TrustedCompany extends Component{
    render(){
        return(
            <div className="trusted-company bg_color--1 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--35">
                                <h3 className="heading mb--15 fontWeight600">Trusted services from top-rated company</h3>
                                <p>It is about us being able to offer help with the branding campaign, product <br /> presentation, and advertisement running across social media.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--50">
                        <Brand2 />
                    </div>
                </div>
            </div>
        )
    }
}
export default TrustedCompany;
