import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CountersOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 598,
                countTitle: 'Successfully served',
                subtitle: 'Happy clients'
            },

            {
                countNum : 128,
                countTitle: 'Successfully completed',
                subtitle: 'Branding projects'
            },

            {
                countNum : 114,
                countTitle: 'Recruit more than',
                subtitle: 'Professional experts'
            },

            {
                countNum : 109,
                countTitle: 'Published widely',
                subtitle: 'Blog posts'
            }
        ];
        let DataList = Data.map((val, i)=>{
            return(
                <div className="counterup_style--1 col-md-3 col-sm-6 col-12" key={i}>
                    <h2 className="title">{val.countTitle}</h2>
                    <h2 className="counter">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                        </VisibilitySensor>
                    </h2>
                    <p>{val.subtitle}</p>
                </div>
            )
        });
        return (
            <div className="row mt--40 mt_sm--5">
                {DataList}
            </div>
        )
    }
}
export default CountersOne;