import React, { Component } from "react";
import { ProgressBar } from 'react-bootstrap';

class ProgressTwo extends Component{
    
    render(){
        return(
            // Start Progress Bar
            <div className="progress-bar--1">
                <div className="single-progress custom-color--1">
                    <h6 className="title">UI/UX</h6>
                    <ProgressBar now={81} />
                    <span className="label">81%</span>
                </div>

                <div className="single-progress custom-color--2">
                    <h6 className="title">Branding</h6>
                    <ProgressBar now={72} />
                    <span className="label">72%</span>
                </div>

                <div className="single-progress custom-color--3">
                    <h6 className="title">Marketing</h6>
                    <ProgressBar now={89} />
                    <span className="label">89%</span>
                </div>

                <div className="single-progress custom-color--4">
                    <h6 className="title">Photography</h6>
                    <ProgressBar now={57} />
                    <span className="label">57%</span>
                </div>
            </div>
            // End Progress Bar
        )
    }
}
export default ProgressTwo;
