import React, { Component } from "react";
import BoxIconSix from "../../../elements/component/box-icon/BoxIconSix";

class Eservice extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title--3 text-center mb--40 mb_sm--10 mb_md--10">
                                    <span>What’s included?</span>
                                    <h2 className="title fontWeight600">We deliver the best services for app design and coding</h2>
                                </div>
                            </div>
                        </div>
                        <BoxIconSix />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Eservice;
