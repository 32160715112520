import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";

class SocialShare extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Social Networks || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Social Networks'} />
                {/* End Breadcrumb Area */}

                <main className="page-wrapper">

                    {/* Start Social Share */}
                    <div className="social-share-area ptb--120 bg-color" data-bg-color="#ffffff">
                        <div className="container">
                            <div className="row">

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Icon Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="social-icon">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Large Icon Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="social-icon large-icon">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Extra Large Icon Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="social-icon exlarge-icon">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Flat Rounded Icon Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="social-share flat-rounded">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Icon & Tilte Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="icon-title">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i>facebook</a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i>twitter</a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i>instagram</a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i>dribbble</a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i>pinterest</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                {/* Start Single Social Share */}
                                <div className="col-lg-12 pb--50">
                                    <div className="section-title mb--30">
                                        <h4>Tilte Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="title-style">
                                            <li><a href="https://www.facebook.com/"><span>facebook</span></a></li>
                                            <li><a href="https://twitter.com/"><span>twitter</span></a></li>
                                            <li><a href="https://www.instagram.com/"><span>instagram</span></a></li>
                                            <li><a href="https://dribbble.com/"><span>dribbble</span></a></li>
                                            <li><a href="https://www.pinterest.com/"><span>pinterest</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Social Share */}

                                <div className="col-lg-12">
                                    {/* Start Single Social Share */}
                                    <div className="section-title mb--30">
                                        <h4>Solid Rounded Icon Style</h4>
                                    </div>
                                    <div className="social-network-wrapper">
                                        <ul className="social-share">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                    {/* End Single Social Share */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* End Social Share */}

                    {/* Start Social Share */}
                    <div className="social-share-area ptb--120 theme-gradient">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title">
                                        <h4 className="text-white">Custom Skin</h4>
                                    </div>
                                    <div className="social-network-wrapper mt--40">
                                        <ul className="social-share text-white">
                                            <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                            <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                    
                                    <div className="social-network-wrapper mt--90">
                                        <ul className="title-style customskin-color">
                                            <li><a href="https://www.facebook.com/"><span>facebook</span></a></li>
                                            <li><a href="https://twitter.com/"><span>twitter</span></a></li>
                                            <li><a href="https://www.instagram.com/"><span>instagram</span></a></li>
                                            <li><a href="https://dribbble.com/"><span>dribbble</span></a></li>
                                            <li><a href="https://www.pinterest.com/"><span>pinterest</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Social Share */}
                </main>
            </div>
        )
    }
}
export default SocialShare;
