import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

class TeamTwo extends Component{
    render(){
        const{ sectionTitle ,sectionDescription } = this.props

        // Start Team Loop
        const allTeamData = this.props.teamData.map((team, index) => (
            <div className="team_style--2 text-center" key={index}>
                <div className="inner">
                    <div className="thumbnail">
                        <img src={`/assets/images/team/team-cat-${team.images}.png`} alt="Team Images"/>
                    </div>
                    <h4 className="title">{team.heading}</h4>
                    <p>{team.description}</p>
                    <ul className="social-share">
                        <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        ));
        // End Team Loop
        const teamActivation = {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            draggable: true,
            dots: true,
            focusOnSelect: true,
            accessibility: true,
            responsive: [
                {
                  breakpoint: 1199,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 769,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 481,
                  settings: {
                    slidesToShow: 1,
                    arrows: false,
                  }
                },
            ]
        }
        return(
            <React.Fragment>
                <div className="team-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--10 mb_sm--10">
                                    <h2 className="text-white">{sectionTitle}</h2>
                                    <p className="text-white">{sectionDescription}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <div className="team-activation-wrapper slick-default-dots slick-custom-arrow button--white mb--80">
                                    <Slider {...teamActivation}>
                                        {allTeamData}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

//Props Types
TeamTwo.propTypes = {
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    teamData: PropTypes.array
};


//Default Props
TeamTwo.defaultProps = {
    sectionTitle: "Our experienced experts",
    sectionDescription: "Cooperation unifies the best team mates.",
    teamData: [
        {
            images: "01",
            heading: "Chad Brewer",
            description: "Director"
        },
        {
            images: "02",
            heading: "Dollie Horton",
            description: "Marketing"
        },
        {
            images: "03",
            heading: "Fannie Garcia",
            description: "Director"
        },
        {
            images: "04",
            heading: "Dollie Horton",
            description: "Marketing"
        },
        {
            images: "04",
            heading: "Dollie Horton",
            description: "Marketing"
        },
        
    ]
};

export default TeamTwo;
