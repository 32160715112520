import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Headerdefault from '../components/header/Headerdefault';
import Brand from '../components/brand/Brand';
import TabsTwo from '../elements/component/tabs/TabsTwo';
import ProgressTwo from '../elements/component/progressbar/ProgressTwo';
import PricingBoxOne from '../elements/component/pricingBox/PricingBoxOne';
import CountersOne from '../elements/component/counters/CountersOne';
import Accordion01 from '../components/accordion/Accordion';
import CalltoActionThree from '../elements/component/callto-action/CalltoActionThree';
import SoftwareService from '../components/layouts/software/SoftwareService';
import ContactTwo from '../elements/component/contact/ContactTwo';
import Footer from '../components/footer/Footer';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class Software extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Software || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <Headerdefault headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--software fullscreen">
                    <div className="slider-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="slider-text">
                                    <h2 className="title">Your business deserves better software </h2>
                                    <p className="description">No coding skills required to create unique sites.</p>
                                    <button className="ar-button secondary style-flat with-left" type="submit"><span className="button-text"><i className="fas fa-download"></i> Get AeroLand 4.1</span></button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="/assets/images/bg/slider-software-slide-02.png" alt="SLider Images"/>
                                </div>
                            </div>
                        </div>
                        <div className="bg-shape-images">
                            <img src="/assets/images/bg/aeroland-slider-software-slide-01.png" alt="App Landing"/>
                        </div>
                    </div>
                </div>
                {/* End Slider Area */}

                {/* Start Brand Area */}
                <Brand />
                {/* End Brand Area */}

                {/* Start Service Area */}
                <SoftwareService />
                {/* End Service Area */}

                {/* Start tab Area */}
                <TabsTwo />
                {/* End tab Area */}

                {/* Start Pricing Table */}
                <div className="pricing-table-area pt--120">
                    <div className="software-pricing-table">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="/assets/images/aeroland/software-image-01.png" alt="Software images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="software-pricing-inner">
                                    <h2 className="fontWeight400">All-in-one responsive app for you</h2>
                                    <div className="sftware-progress mt--70 mt_sm--30 mt_md--30">
                                        <ProgressTwo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Pricing Table */}

                {/* Start Counter Up Area */}
                <div className="counterup-area bg-color pt_md--60 pt_sm--60 pt_lg--60" data-bg-color="#ffffff">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400 mb--10">No stories, facts only</h2>
                                    <p>Offer your business with the best assistance for growth.</p>
                                </div>
                            </div>
                        </div>
                        <CountersOne />
                    </div>
                </div>
                {/* End Counter Up Area */}

                {/* Start Pricing Table Area */}
                <div className="pricing-table-area bg-color ptb--120" data-bg-color="#F6F5F9">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400 mb--10">Affordable for everyone!</h2>
                                    <p>If you are planning on developing a product landing app or website, take a look at this <br /> beautiful-crafted and error-free WordPress theme.</p>
                                </div>
                            </div>
                        </div>
                        <PricingBoxOne />
                    </div>
                </div>
                {/* End Pricing Table Area */}


                {/* Start Call TO Action */}
                <div className="call-to-action-wrapper">
                    <CalltoActionThree />
                </div>
                {/* End Call TO Action */}

                {/* Start Accordion Area */}
                <div className="software-accordion-area bg-color ptb--120" data-bg-color="#F6F5F9">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400 mb--10">Frequently Asked Questions</h2>
                                    <p>It’s our pleasure to answer any questions you have</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mt--60">
                            {/* Start Single Accordion */}
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="software-accordion">
                                    <Accordion01 />
                                </div>
                            </div>
                            {/* End Single Accordion */}
                            
                            {/* Start Single Accordion */}
                            <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                                <div className="software-accordion">
                                    <Accordion01 />
                                </div>
                            </div>
                            {/* End Single Accordion */}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-title text-center mt--70">
                                    <h4 className="color-3">Still have further questions? <a className="link-hover color-4" href="/">Contact us</a> </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="thumbnail text-center mt--70">
                                    <img src="/assets/images/aeroland/software-image-02.png" alt="App landing"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Accordion Area */}
                <ContactTwo />
                <Footer />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </React.Fragment>
        )
    }
}
export default Software;