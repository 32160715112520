import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class ServiceTwo extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Social media boost',
            },
            {
                icon: '02',
                title: 'In-depth research analysis',
            },
            {
                icon: '03',
                title: 'Scientifically proven progress',
            },
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col className="mt--30 single-service" lg="4" md="6" sm="6" xs="12" key={i}>
                    <div className="service_style--2">
                        <div className="icon">
                            <img src={`/assets/images/box-img/branding-box-image-${val.icon}.png`} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}
export default ServiceTwo;
