import React, { Component } from "react";


class ListTwo extends Component{
    render(){
        var ListItem = [
            {description: 'Item text 01', key: 0},
            {description: 'Item text 02', key: 1},
            {description: 'Item text 03', key: 2},
            {description: 'Item text 04', key: 2},
            {description: 'Item text 05', key: 2}
        ];
        return(
            <ol className="list-style--2">
                {ListItem.map(question => {
                    return (
                        <li key={question.key}>{question.description}</li>
                    );
                })}
            </ol>
        )
    }
}
export default ListTwo;




