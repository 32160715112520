import React, { Component } from "react";

class ContactTwo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fNameValue: '',
            emailValue: '',
        };
    };
    render(){
        return(
            <div className="subscribe-area theme-gradient ptb--90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 mb_sm--20">
                                <h2 className="text-white font--40">493 businesses signed up <br/> last week. Join them!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="newsletter-form--1">
                                <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="POST" noValidate>
                                    <label className="form-item" htmlFor='AERO1'>
                                        <input 
                                            type="text" 
                                            name="FNAME" 
                                            id="AERO1" 
                                            value={this.state.fNameValue} 
                                            onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                                            placeholder="Your Name"
                                        />
                                    </label>
                                    <label className="form-item" htmlFor='AERO2'>
                                        <input 
                                            type="email" 
                                            name="EMAIL" 
                                            id="AERO2"
                                            value={this.state.emailValue} 
                                            onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
                                            autoCapitalize="off" 
                                            autoCorrect="off"
                                            placeholder="Email"
                                        /> 
                                    </label>
                                    <button className="form-item button" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>
                                </form>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mt--50">
                                <h4 className="text-white font--15 mb--0">What’s next in AeroLand? <a className="color-4 link-hover" href="/">Learn more</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
export default ContactTwo;