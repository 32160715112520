import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import ContactOne from "./component/contact/ContactOne";
import ContactTwo from "./component/contact/ContactTwo";
import ContactThree from "./component/contact/ContactThree";

class Contact extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Contact || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
               <Breadcrumb title={'Contact & Mailchimp Form'} />
                {/* End Breadcrumb Area */}

                {/* Start Contact Form Area */}
                <div className="contact-form-area ptb--120 bg-color" data-bg-color="#ffffff">
                    <ContactOne />
                </div>
                
                {/* End Contact Form Area */}

                {/* Start Contact Form Area */}
                <ContactTwo />
                {/* End Contact Form Area */}

                {/* Start Contact Form Area */}
                <ContactThree />
                {/* End Contact Form Area */}
                
            </div>
        )
    }
}
export default Contact;