import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class IconBox2 extends Component{
    render(){
        let Data =[
            {
                image: '01',
                title: 'Define your ideas',
                desc: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
            },
            {
                image: '02',
                title: 'Make schedules',
                desc: 'An app that is designed for making plans, writing notes, record ideas and remind of your schedules.'
            },
            {
                image: '03',
                title: 'Manage time',
                desc: 'Controlling your daily tasks, assign and discuss on projects, checking deadlines and set time to relax.'
            },
            {
                image: '04',
                title: 'Working mobile',
                desc: 'Its easy to switch between different devices while keeping your data safe with secure login.'
            },
        ]
        let DataList = Data.map((val , i) => {
            return (
                <Col lg="6" md="6" sm="12" xs="12" key={i}>
                    <div className="icon-box--2">
                        <div className="content">
                            <div className="icon">
                                <img src={`/assets/images/box-img/app-showcase-box-icon-image-${val.image}.png`} alt="App Landing"/>
                            </div>
                            <div className="inner">
                                <h5>{val.title}</h5>
                                <p>{val.desc}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        })
        return(
            <Row>
                {DataList}
            </Row>
        )
    }
}
export default IconBox2

