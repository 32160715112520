import React, { Component } from "react";
import Counterup from "../../counterup/CounterUp";


class CounterService extends Component {
    render(){
        return(
            <div className="brand-counterup-area bg_color--1 pb--120">
                <div className="container">
                    <Counterup />
                </div>
            </div>
        )
    }
}
export default CounterService;
