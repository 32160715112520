import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import TestimonialTwo from "../elements/component/testimonial/TestimonialTwo";

class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Testimonial || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Testimonial'} />
                {/* End Breadcrumb Area */}

                {/* Start Testimonial Area */}
                <div className="testimonial-area ptb--120 bg_color--4">
                    <TestimonialTwo />
                </div>
                {/* End Testimonial Area */}

            </React.Fragment>
        )
    }
}
export default Testimonial;