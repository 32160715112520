import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

class ResponsiveApp extends Component {
    render(){
        return(
            <div className="responsive-app-area">
                <div className="responsive-app-wrapper">
                    <Row className="align-items-center">
                        <Col md={6} sm={12}>
                            <div className="thumbnail">
                                <img src="/assets/images/aeroland/aeroland_slider-sass-image-03.png" alt="All Landing"/>
                            </div>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className="content mt_sm--40">
                                <h3 className="heading">All-in-one responsive <br /> app for you </h3>
                                <p>It is about us being able to offer help with the branding campaign, product presentation, and advertisement running across social media.</p>
                                <button className="ar-button style-flat" type="submit"><span className="button-text">Learn More</span></button>
                                <h6 className="next-button">What’s next in AeroLand? 
                                    <Link className="link-hover" to={`${process.env.PUBLIC_URL}/`}>
                                        Learn more
                                    </Link>
                                </h6>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default ResponsiveApp

