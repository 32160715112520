import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconSeven extends Component{
    render(){
        return(
            <Row>
                <Col className="mt--30" lg="4" md="6" sm="12" xs="12">
                        <div className="box-icon--7">
                            <div className="svg-inner">
                                <svg>
                                    <path fill="none" d="M3.7079999999999984,35A28.292,28.292 0,1,1 60.292,35A28.292,28.292 0,1,1 3.7079999999999984,35"></path>
                                    <path fill="none"></path>
                                    <path fill="none" d="M26,29L37,40"></path>
                                    <path fill="none" d="M36.9,7C36.965,6.677,37,6.342,37,6 c0-2.761-2.239-5-5-5s-5,2.239-5,5c0,0.342,0.035,0.677,0.1,1"></path>
                                    <path fill="none" d="M32,7L32,12" ></path>
                                    <path fill="none" d="M32,58L32,63" ></path>
                                    <path fill="none" d="M60,35L55,35" ></path>
                                    <path fill="none" d="M9,35L4,35" ></path>
                                </svg>
                            </div>
                            <div className="content">
                                <h5 className="heading">Define your ideas</h5>
                                <p>Share ideas of how you would like your app to perform to achieve goals.</p>
                            </div>
                        </div>
                </Col> 
                <Col className="mt--30" lg="4" md="6" sm="12" xs="12">
                    <div className="box-icon--7">
                        <div className="svg-inner">
                            <svg>
                                <path fill="none" d="M21,40v5h22l0.001-5.107C49,36.195,53,29.564,53,22 c0-11.598-9.402-21-21-21s-21,9.402-21,21C11,29.565,14.998,36.304,21,40z"></path>
                                <path fill="none" d="M28,45L25,25"></path>
                                <path fill="none" d="M36,45L39,25"></path>
                                <path fill="none" d="M25,26L29,29L32,26L35,29L39,26"></path>
                                <path fill="none" width="22" height="6" d="M21 45 L43 45 L43 51 L21 51 Z"></path>
                                <path fill="none" width="18" height="6" d="M23 51 L41 51 L41 57 L23 57 Z"></path>
                                <path fill="none" width="14" height="6" d="M25 57 L39 57 L39 63 L25 63 Z"></path>
                            </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Define your ideas</h5>
                            <p>Share ideas of how you would like your app to perform to achieve goals.</p>
                        </div>
                    </div>
                </Col> 
                <Col className="mt--30" lg="4" md="6" sm="12" xs="12">
                    <div className="box-icon--7">
                        <div className="svg-inner">
                            <svg>
                                <path fill="none" width="62" height="50" d="M1 7 L63 7 L63 57 L1 57 Z"></path>
                                <path fill="none" d="M1,15L63,15"></path>
                                <path fill="none" d="M10,11L6,11"></path>
                                <path fill="none" d="M18,11L14,11"></path>
                                <path fill="none" d="M26,11L22,11"></path>
                            </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Define your ideas</h5>
                            <p>Share ideas of how you would like your app to perform to achieve goals.</p>
                        </div>
                    </div>
                </Col> 
            </Row>
        )
    }
}

export default BoxIconSeven;