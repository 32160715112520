import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class BoxIconTwo extends Component{
    render(){
        let Data =[
            {
                iconinner: 'icon-inner',
                icon: 'fab fa-windows',
                title: 'Office Computers'
            },
            {
                iconinner: 'icon-inner window',
                icon: 'fab fa-react',
                title: 'PERN Stack'
            },
            {
                iconinner: 'icon-inner apple',
                icon: 'fab fa-angular',
                title: 'MEAN Stack'
            },
            {
                iconinner: 'icon-inner java',
                icon: 'fab fa-java',
                title: 'Java '
            },
            {
                iconinner: 'icon-inner android',
                icon: 'fab fa-python',
                title: 'Python'
            },
            {
                iconinner: 'icon-inner php',
                icon: 'fab fa-php',
                title: 'PHP'
            },
            {
                iconinner: 'icon-inner android',
                icon: 'fab fa-android',
                title: 'Android'
            },
            {
                iconinner: 'icon-inner r-project',
                icon: 'fab fa-r-project',
                title: 'Data Science'
            },
            {
                iconinner: 'icon-inner apple',
                icon: 'fab fa-jenkins',
                title: 'Jenkins'
            },
            {
                iconinner: 'icon-inner java',
                icon: 'fab fa-git-square',
                title: 'GIT '
            },
            {
                iconinner: 'icon-inner window',
                icon: 'fab fa-ember',
                title: 'Strapi'
            },
            {
                iconinner: 'icon-inner node',
                icon: 'fab fa-node-js',
                title: 'node js'
            },


        ]
        let DataList = Data.map((val , i) => {
            return (
                <Col lg="2" md="4" sm="4" xs="6" key={i}>
                    <div className="box-icon--2">
                        <div className="content">
                            <div className={`${val.iconinner}`}>
                                <i className={`${val.icon}`}></i>
                            </div>
                            <div className="inner">
                                <h5>{val.title}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        })
        return(
            <Row className="row--0">
                {DataList}
            </Row>
        )
    }
}
export default BoxIconTwo;
