export const portfolioReducers = (state = {}, action) => {
    return [
        {
            id: '1',
            thumbnail: '01',
            title: 'Print Design Prospect',
            gridwidth2: '',
            gridheight2: '',
            category: 'BRANDING',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },
        {
            id: '2',
            thumbnail: '02',
            title: 'Political Fabric Backdrop',
            gridwidth2: '',
            gridheight2: '',
            category: 'ILLUSTRATION',
            description: 'Galvanized by our current political climate and accelerated by our congestion of digital media, it is becoming increasingly difficult to discerningly seek out the truth. As a result, we’re seeing specialist publications flourish, where truth and credibility is the currency that cuts through the noise, and the finite consolidation of information offered by print becomes increasingly more appetizing. (Source: designweek.co.uk)',
        },
        {
            id: '3',
            thumbnail: '03',
            title: '2018 President Summit',
            gridwidth2: '',
            gridheight2: '',
            category: 'ILLUSTRATION',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },
        {
            id: '4',
            thumbnail: '04',
            title: 'Lonity Brand New Look',
            gridwidth2: '',
            gridheight2: '',
            category: 'CONCEPT',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },
        {
            id: '5',
            thumbnail: '05',
            title: 'Finding Alternativel Resources',
            gridwidth2: '',
            gridheight2: '',
            category: 'CONCEPT',
            description: 'During the 2018 presidents’ summit in Helsinki, Finnish newspaper Helsingin Sanomat took control of 300 billboards and advertising spaces on the routes from the airport to the summit, filling them with headlines and inflammatory statements, from president Trump’s attitude to press freedom. (Source: designweek.co.uk)',
        },
        {
            id: '6',
            thumbnail: '06',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'As natural resources continue to dwindle, I think in 2019 and beyond furniture designers and craft makers will be looking to alternative materials, both in terms of being resourceful with existing options and creating new ones. Through the Crafts Council’s innovation programme I have come across some really interesting applications and adaptations of materials. Source: designweek.co.uk)',
        },
        {
            id: '7',
            thumbnail: '07',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: '“We mapped the movement of how these birds will glide,” Manchipp says. “We saw that they start in small circles which become wider [as they rise]. We digitised this and turned it into visual properties which we are using as branding devices. (Source: designweek.co.uk)',
        },
        {
            id: '8',
            thumbnail: '08',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'Simon Manchipp, founder and creative director at SomeOne, says that while electric vehicle usage is “quite established” in many cities across Europe, “it becomes more complicated when people were trying to cover long distances”. (Source: designweek.co.uk)',
        },
        {
            id: '9',
            thumbnail: '09',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },
        {
            id: '10',
            thumbnail: '10',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },

        {
            id: '11',
            thumbnail: '11',
            title: 'Digitized Visual Projects',
            gridwidth2: 'grid-width-2',
            gridheight2: 'grid-height-2',
            category: 'ART DIRECTION',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },

        {
            id: '12',
            thumbnail: '12',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: 'grid-height-2',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',

        },
        {
            id: '13',
            thumbnail: '13',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },
        {
            id: '14',
            thumbnail: '14',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },
        {
            id: '15',
            thumbnail: '15',
            title: 'Digitized Visual Projects',
            gridwidth2: 'grid-width-2',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'It sounds impossible, but it’s apparently achievable by using smart technology to beam sound directly from source to ear, courtesy of what is described as the world’s inaugural “dynamic focused sound” system. “Applying this new technology will enable us to open a world of possibilities for privately owned vehicles.” (Source: designweek.co.uk)',
        },

        {
            id: '16',
            thumbnail: '16',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '17',
            thumbnail: '17',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '18',
            thumbnail: '18',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '19',
            thumbnail: '19',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '20',
            thumbnail: '20',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '21',
            thumbnail: '21',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '22',
            thumbnail: '22',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ART DIRECTION',
            description: 'With our political fabric as a backdrop, the need for credible disruption is needed even more. I feel there will be a tide of courageous, authentic, reductive design, which highlights the importance of true craft, free from over-embellishment of process, decoration and anything that ‘gets in the way of the message’. (Source: designweek.co.uk)',
        },

        {
            id: '22',
            thumbnail: '22',
            title: 'Digitized Visual Projects',
            gridwidth2: '',
            gridheight2: '',
            category: 'ILLUSTRATION',
            description: 'Galvanized by our current political climate and accelerated by our congestion of digital media, it is becoming increasingly difficult to discerningly seek out the truth. As a result, we’re seeing specialist publications flourish, where truth and credibility is the currency that cuts through the noise, and the finite consolidation of information offered by print becomes increasingly more appetizing. (Source: designweek.co.uk)',
        },
    ]

}