import React, { Component } from "react";

class FooterFive extends Component{
    constructor(props) {
        super(props);
        this.state = {
            emailValue: '',
        };
    };
    render(){
        return(
            <footer className="footer footer-style--5 ptb--150">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h5 className="color-3 mb--35">Enter your email <span className="color-4">to get free chapter-01</span></h5>
                                </div>

                                <div className="newsletter-inner text-center">
                                    <form className="mb--140 mb_sm--50 mb_md--30" action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="POST" noValidate>
                                        <label className="form-item" htmlFor='AERO2'>
                                            <input 
                                                type="email" 
                                                name="EMAIL" 
                                                id="AERO2"
                                                value={this.state.emailValue} 
                                                onChange={(e)=>{this.setState({emailValue: e.target.value});}} 
                                                autoCapitalize="off" 
                                                autoCorrect="off"
                                                placeholder="Enter your email"
                                            /> 
                                        </label>
                                    </form>
                                    <ul className="title-style">
                                        <li><a href="https://www.facebook.com/"><span>facebook</span></a></li>
                                        <li><a href="https://twitter.com/"><span>twitter</span></a></li>
                                        <li><a href="https://www.instagram.com/"><span>instagram</span></a></li>
                                        <li><a href="https://dribbble.com/"><span>dribbble</span></a></li>
                                        <li><a href="https://www.pinterest.com/"><span>pinterest</span></a></li>
                                    </ul>
                                </div>
                                <div className="copyright-inner mt--30 d-flex flex-wrap justify-content-center">
                                    <p className="color-3">© 2020 AeroLand. All Rights Reserved</p>
                                    <p className="color-3">(+00) 123 567990</p>
                                    <p className="color-3"><a className="color-3 link-hover" href="tel:+00123567990">(+00) 123 567990</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterFive;

