import React, { Component } from "react";
import Slider from "react-slick";
import {slider1} from "../../Home/script";


class ServiceThree extends Component{
    render(){
        let Data = [
            {
                thumb : '01',
                details: 'Our branding professionals have unique ways to avoid the well-worn path. They can get on a new original one for finding resolutions by imagining your company as a piece and study how to make it fit into the big picture.',
                title: 'How to Fit into The Big Picture',
            },
            {
                thumb : '02',
                details: 'Our branding professionals have unique ways to avoid the well-worn path. They can get on a new original one for finding resolutions by imagining your company as a piece and study how to make it fit into the big picture.',
                title: 'How to Fit into The Big Picture',
            },
            {
                thumb : '03',
                details: 'Our branding professionals have unique ways to avoid the well-worn path. They can get on a new original one for finding resolutions by imagining your company as a piece and study how to make it fit into the big picture.',
                title: 'How to Fit into The Big Picture',
            },

        ];
    
        let DataList = Data.map((val, i) =>{
            return(
                <div className="row d-flex align-items-center" key={i}>
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img src={`/assets/images/mokup/aeroland-branding-picture-${val.thumb}.png`} alt="Testimonial Images"/>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="slide-content">
                            <div className="slide-info">
                                <h5 className="title">{val.title}</h5>
                                <p>{val.details}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div className="service-wrapper slick-default-dots dots-position-right">
                <Slider {...slider1}>
                    {DataList}
                </Slider>
            </div>
        )
    }
}
export default ServiceThree;
