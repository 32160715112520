import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import HeaderTwo from '../components/header/HeaderTwo';
import AgencyService from '../components/layouts/digitalagency/AgencyService';
import HowToWork from '../components/layouts/digitalagency/WorkProcess';
import DgCooperation from '../components/layouts/digitalagency/DgCooperation';
import DgGallery from '../components/layouts/digitalagency/DgGallery';
import ContactThree from "../elements/component/contact/ContactThree";
import FooterFour from "../components/footer/FooterFour";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { sliderdefault } from './script';
import Slider from "react-slick";

class DigitalAgency extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Digital Agency || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderTwo />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <Slider {...sliderdefault} className="slick-default-dot slick-animation">
                    {/* Start Single Slide  */}
                    <div className="slider-area slider--digitalagency bg_image bg_image--19">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="wrapper">
                                        <div className="inner text-center">
                                            <h2>Nice to meet you!</h2>
                                            <h1>We’re a small team of <br /> storytellers</h1>
                                            <button className="ar-button style-flat"><span className="button-text">Talk to our team</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     {/* End Single Slide  */}
                    {/* Start Single Slide  */}
                    <div className="slider-area slider--digitalagency bg_image bg_image--20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="wrapper">
                                        <div className="inner text-center">
                                            <h2>Nice to meet you!</h2>
                                            <h1>We’re a small team <br /> of storytellers</h1>
                                            <button className="ar-button style-flat"><span className="button-text">Talk to our team</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     {/* End Single Slide  */}
                </Slider>
                
                {/* End Slider Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--100 bg-color" data-bg-color="#ffffff">
                    <AgencyService />
                </div>
                {/* Start Service Area */}
                

                {/* Start Working Process Area */}
                <div className="working-process-area pb--120">
                    <div className="wrapper work-proces-bg">
                        <HowToWork /> 
                    </div>
                </div>
                {/* End Working Process Area */}


                {/* Start Cooperation Area */}
                <div className="dg-cooperation-area cooperation-bg-images pb--40">
                    <DgCooperation />
                </div>
                {/* End Cooperation Area */}

                {/* Start Gallery Area */}
                <div className="bg-gallery-area ptb--120 bg-color">
                    <DgGallery />
                </div>
                {/* End Gallery Area */}

                {/* Start Subscribe Area */}
                <div className="newsletter-area bg_color--3">
                    <ContactThree />
                </div>
                {/* End Subscribe Area */}

                {/* Start Footer Style */}
                <FooterFour />
                {/* End Footer Style */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
            </React.Fragment>
        )
    }
}

export default DigitalAgency;