import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CountersTwo extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 1760,
                countTitle: 'HAPPY CLIENTS',
            },
            {
                countNum : 560,
                countTitle: 'FINISHED PROJECTS',
            },
            {
                countNum : 768,
                countTitle: 'LOCAL BRANCHES',
            },
            {
                countNum : 203,
                countTitle: 'BLOG POSTS',
            }
        ];
        let DataList = Data.map((val, i)=>{
            return(
                <div className="counterup_style--3 col-md-3 col-6" key={i}>
                    <h2 className="counter text-white">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <CountUp end={this.state.didViewCountUp ? val.countNum : 0} />
                        </VisibilitySensor>
                    </h2>
                    <h2 className="title">{val.countTitle}</h2>
                </div>
            )
        });
        return (
            <div className="row mt--40 mt_sm--5">
                {DataList}
            </div>
        )
    }
}
export default CountersTwo;