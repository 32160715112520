import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconEight extends Component{
    render(){
        let Data = [
            {
                icon: 'fa fa-users',
                title: 'Simple order submit',
                desc: 'Clients can easily place a payment order through our fully automatic system by filling an online form.'
            },
            {
                icon: 'fa fa-heartbeat',
                title: 'Wireless payment',
                desc: 'Payments are made without the need for clients to appear in person or submit any legal documents.'
            },
            {
                icon: 'fa fa-ambulance',
                title: 'Hassle-free payment',
                desc: 'The security of all payments are reinforced by using Secure Sockets Layer (SSL) 128 bit encryption.'
            },
            {
                icon: 'fa fa-briefcase-medical',
                title: 'Mobile app support',
                desc: 'AeroLand is compatible for any personal devices such as laptops, desktops, computers, mobile phone.'
            },
            {
                icon: 'fa fa-bell',
                title: 'Quick account access',
                desc: 'Access to your account can be granted with the assistance from a fully automated fingerprint.'
            },
            {
                icon: 'fa fa-suitcase',
                title: 'Online credit services',
                desc: 'Our automated machines programmed for clients to carry out online credit check or other services.'
            },
        ]
        
        let DataList = Data.map((val , i) => {
            return(
                <Col className="mt--40" lg="4" md="6" sm="12" xs="12" key={i}>
                    <div className="box-icon--8">
                        <div className="content-header">
                            <i className={`${val.icon}`}></i>
                            <h5 className="heading">{val.title}</h5>
                        </div>
                        <div className="content">
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row>
                {DataList}
            </Row>
        )
    }
}

export default BoxIconEight;