import React, { Component } from "react";

class PricingBoxOne extends Component{
    render(){
        return(
            <div className="row">
                {/* Start Single Pricing Table */}
                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <div className="image">
                                <img src="/assets/images/mokup/pricing-image-01.png" alt="App Landing React"/>
                            </div>
                            <h5 className="title">Free</h5>
                            <div className="pricing-wrap">
                                <div className="price-wrap-inner">
                                    <h6 className="currency">$</h6>
                                    <h6 className="price">0</h6>
                                    <h6 className="period">/mo</h6>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-content">
                            <ul className="pricingfeature">
                                <li>03 projects</li>
                                <li>Power And Predictive Dialing</li>
                                <li>Quality &amp; Customer Experience</li>
                                <li><strong>Try for free, forever!</strong></li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <button className="ar-button style-flat button-wih-border" type="submit">
                                <span className="button-text">Learn More</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* End Single Pricing Table */}

                {/* Start Single Pricing Table */}
                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div className="pricing-table">
                        <div className="feature-inner-mark">
                            <span>Popular Choice</span>
                        </div>
                        <div className="pricing-header">
                            <div className="image">
                                <img src="/assets/images/mokup/pricing-image-02.png" alt="App Landing React"/>
                            </div>
                            <h5 className="title">Personal</h5>
                            <div className="pricing-wrap">
                                <div className="price-wrap-inner">
                                    <h6 className="currency">$</h6>
                                    <h6 className="price">19</h6>
                                    <h6 className="period">/mo</h6>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-content">
                            <ul className="pricingfeature">
                                <li>10 projects</li>
                                <li>Power And Predictive Dialing</li>
                                <li>Quality &amp; Customer Experience</li>
                                <li>24/7 phone and email support</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <button className="ar-button style-flat" type="submit">
                                <span className="button-text">Learn More</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* End Single Pricing Table */}

                {/* Start Single Pricing Table */}
                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <div className="image">
                                <img src="/assets/images/mokup/pricing-image-03.png" alt="App Landing React"/>
                            </div>
                            <h5 className="title">Group</h5>
                            <div className="pricing-wrap">
                                <div className="price-wrap-inner">
                                    <h6 className="currency">$</h6>
                                    <h6 className="price">29</h6>
                                    <h6 className="period">/mo</h6>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-content">
                            <ul className="pricingfeature">
                                <li>50 projects</li>
                                <li>Power And Predictive Dialing</li>
                                <li>Quality &amp; Customer Experience</li>
                                <li>24/7 phone and email support</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <button className="ar-button style-flat button-wih-border" type="submit">
                                <span className="button-text">Learn More</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* End Single Pricing Table */}

                {/* Start Single Pricing Table */}
                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <div className="image">
                                <img src="/assets/images/mokup/pricing-image-01.png" alt="App Landing React"/>
                            </div>
                            <h5 className="title">Enterprise</h5>
                            <div className="pricing-wrap">
                                <div className="price-wrap-inner">
                                    <h6 className="currency">$</h6>
                                    <h6 className="price">49</h6>
                                    <h6 className="period">/mo</h6>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-content">
                            <ul className="pricingfeature">
                                <li>Unlimited project</li>
                                <li>Power And Predictive Dialing</li>
                                <li>Quality &amp; Customer Experience</li>
                                <li>24/7 phone and email support</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <button className="ar-button style-flat button-wih-border" type="submit">
                                <span className="button-text">Learn More</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* End Single Pricing Table */}
            </div>
        )
    }
}
export default PricingBoxOne;