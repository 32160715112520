import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import ListOne from "./component/list/ListOne";
import ListTwo from "./component/list/ListTwo";

class Lists extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Lists || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Lists'} />
                {/* End Breadcrumb Area */}

                {/* Start list Style    */}
                <div className="list-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <h4 className="title mb--40  mb_sm--20">Check List</h4>
                                <ListOne />
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 mt_sm--30">
                                <h4 className="title mb--40 mb_sm--20">Automatic Numbered List</h4>
                                <ListTwo />
                            </div>
                        </div>    
                    </div>
                </div>
                {/* End list Style    */}

            </div>
        )
    }
}

export default Lists;
