import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Service extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            },

            {
                icon: '02',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            },

            {
                icon: '03',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            },

            {
                icon: '04',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            },

            {
                icon: '05',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            },

            {
                icon: '06',
                title: 'Reliable Services',
                desc: 'You can trust us that your projects will be kept confidential and safe to outsiders.'
            }
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col className="mt--30" lg="4" md="6" sm="6" xs="12" key={i}>
                    <div className="service">
                        <div className="icon">
                            <img src={`/assets/images/box-img/aeroland-box-image-${val.icon}.png`} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}

export default Service;
