import React, { Component } from "react";

import Slider from "react-slick"
import {tabSlide1, tabSlide2} from "../../../Home/script";

class  TabsThree extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.setState({
          nav1: this.tabSlide1,
          nav2: this.tabSlide2
        });
    }
    render(){
        return(
            <div className="tabs-three-area tab-style--2 ptb--120 text-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="tab-content-wrapper">
                                <Slider {...tabSlide1} asNavFor={this.state.nav2} ref={slider => (this.tabSlide1 = slider)} className="testimonial-image-slider text-center">
                                    <div className="tab2-content">
                                        <div className="title">
                                            <i className="fa fa-align-left"></i>
                                            <h4>Right Resources</h4>
                                        </div>
                                        <p>Select the right resources for your workload.</p>
                                    </div>
                                    
                                    <div className="tab2-content mt--40">
                                        <div className="title">
                                            <i className="fa fa-rocket"></i>
                                            <h4>Rapid Provisioning</h4>
                                        </div>
                                        <p>Get the flexibility you need to get up and running quickly. Rapidly provision one or hundreds of desktops or servers.</p>
                                    </div>
                                    
                                    <div className="tab2-content mt--40">
                                        <div className="title">
                                            <i className="fa fa-chart-pie"></i>
                                            <h4>No Learning Curve</h4>
                                        </div>
                                        <p>Save time with production ready servers. Save time with pre-installed software.</p>
                                    </div>
                                    
                                </Slider>
                            </div>
                        </div>
                        <div className="col-lg-8 mt_md--40 mt_sm--40">
                            <div className="tab2-thumbnail-wrapper slick-dots-style">
                                <Slider {...tabSlide2} asNavFor={this.state.nav1} ref={slider => (this.tabSlide2 = slider)} className="testimonial-text-slider text-center">
                                    
                                    <div className="thumbnail">
                                        <img src="/assets/images/aeroland/tab-content-image-04.jpg" alt="app landing"/>
                                    </div>
                                    
                                    <div className="thumbnail">
                                        <img src="/assets/images/aeroland/tab-content-image-05.jpg" alt="app landing"/>
                                    </div>
                                    
                                    <div className="thumbnail">
                                        <img src="/assets/images/aeroland/tab-content-image-06.jpg" alt="app landing"/>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TabsThree;