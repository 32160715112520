import React, { Component } from "react";


class PricingBoxFour extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                        <div className="pricing-table--4">
                            <div className="inner">
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src="/assets/images/mokup/pricing-image-08.png" alt="App Landing"/>
                                    </div>
                                    <h5 className="title">Free</h5>
                                </div>
                                <div className="pricing-content">
                                    <p>All the basics for starting a new business app online, limited to one site only. The limit of storage space is 2GB and a 256KB RAM.</p>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">See pricing</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-6 col-xl-4 col-md-6 col-12 mt_sm--30">
                        <div className="pricing-table--4 heighted">
                            <div className="inner">
                                <div className="feature-mark">
                                    <span>Popular Choice</span>
                                </div>
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src="/assets/images/mokup/pricing-image-09.png" alt="App Landing"/>
                                    </div>
                                    <h5 className="title">Professional</h5>
                                </div>
                                <div className="pricing-content">
                                    <p>All the basics for starting a new business app online, limited to one site only. The limit of storage space is 2GB and a 256KB RAM.</p>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">See pricing</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-6 col-xl-4 col-md-6 col-12 mt_md--30 mt_sm--30">
                        <div className="pricing-table--4">
                            <div className="inner">
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src="/assets/images/mokup/pricing-image-10.png" alt="App Landing"/>
                                    </div>
                                    <h5 className="title">Advance</h5>
                                </div>
                                <div className="pricing-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">See pricing</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}
 
                </div>
            </React.Fragment>
        )
    }
}



export default PricingBoxFour;

