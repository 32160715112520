import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FiDownload, FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";

class Demo extends Component {
  render() {
    let Data = [
      {
        icon: "fa-fire",
        title: "Compact Design",
        desc:
          "A design that allows users to preserve more open space and makes more efficient use of landing site."
      },
      {
        icon: "fa-users",
        title: "Easy to Customize",
        desc:
          "Easy to Customize great combination of elements and styles made up 70+ layouts"
      },
      {
        icon: "fa-chart-line",
        title: "Fast & Responsive",
        desc:
          "Site loading is optimized for the best performance and loading speed possible with errors minimized."
      }
    ];
    let DataList = Data.map((val, i) => {
      return (
        <Col
          className="single-icon-box mt_sm--30"
          lg="4"
          md="4"
          sm="6"
          xs="12"
          key={i}
        >
          <div className="icon-box">
            <div className="line">
              <div className="dot dot-1"></div>
              <div className="dot dot-2"></div>
              <div className="dot dot-3"></div>
              <div className="dot dot-4"></div>
              <div className="dot dot-5"></div>
              <div className="dot dot-4"></div>
              <div className="dot dot-3"></div>
              <div className="dot dot-2"></div>
              <div className="dot dot-1"></div>
            </div>
            <div className="content">
              <div className="icon">
                <i className={`fa ${val.icon}`}></i>
              </div>
              <h5 className="text-white">{val.title}</h5>
              <p className="text-white">{val.desc}</p>
            </div>
          </div>
        </Col>
      );
    });

    let DataTwo = [
      {
        icon: "01",
        title: "Cross browser Adaptability",
        desc:
          "We run thorough tests on different browsers to ensure optimal performance on all devices."
      },

      {
        icon: "02",
        title: "Fast Loading Speed",
        desc:
          "Aeroland gets a very high pagespeed scores on Pagespeed Insight, Pingdom Tools & GTmetrix."
      },

      {
        icon: "03",
        title: "SEO Friendly",
        desc:
          "All elements are fully optimized to help your site compete with other similar ones in search engine."
      },

      {
        icon: "04",
        title: "Detailed documentation",
        desc:
          "This template are fully covered in better documentation. You can  customize for all elements easily."
      },

      {
        icon: "05",
        title: "Dedicated support",
        desc:
          "Any issues with theme configuration and site building will be solved promptly by our professionals."
      },

      {
        icon: "06",
        title: "Free lifetime updates",
        desc:
          "Consider this app a platform for connecting people from everywhere based on their preferences."
      }
    ];

    let DataListTwo = DataTwo.map((val, i) => {
      return (
        <Col lg="4" md="6" sm="6" xs="12" key={i}>
          <div className="box-icon--4">
            <div className="icon">
              <img
                src={`/assets/images/box-img/startup-box-icon-${val.icon}.png`}
                alt="App Landing"
              />
            </div>
            <div className="content">
              <h5 className="heading">{val.title}</h5>
              <p>{val.desc}</p>
            </div>
          </div>
        </Col>
      );
    });
    return (
      <Fragment>
        <Helmet>
          <title>Aeroland || Responsive App and Website Template</title>
          <meta
            name="description"
            content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template."
          />
        </Helmet>

        {/* Start Header Area */}
        <header className="pre-header-area">
          <div className="wrapper">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-6">
                <div className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/logo/light-logo.png"
                      alt="Logo Images"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-none d-md-block">
                <div className="mainmenu">
                  <nav>
                    <ul className="menu">
                      <li>
                        <a href="/">Landing</a>
                      </li>
                      <li>
                        <a href="#home">Home</a>
                      </li>
                      <li>
                        <a href="#feature">Feature</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-6">
                <div className="header-button text-right">
                  <a
                    className="ar-button style-flat white"
                    href="https://1.envato.market/c/417168/275988/4415?subId1=hastheme&subId2=demo&subId3=https%3A%2F%2Fthemeforest.net%2Fuser%2Fbootxperts%2Fportfolio&u=https%3A%2F%2Fthemeforest.net%2Fuser%2Fbootxperts%2Fportfolio"
                  >
                    <span className="button-text">Buy Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area */}

        {/* Start Slider Area */}
        <div className="landing-hero-area hero-bg-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="landing-hero-inner text-center pt--50 pt_md--20 pt_sm--0">
                  <div className="spacing--1"></div>
                  <h2 className="text-white mb--30">
                    Landing your success, <br /> everything starts with AeroLand
                  </h2>
                  <button
                    className="ar-button secondary style-flat with-left"
                    type="submit"
                  >
                    <span className="button-text">
                      <FiDownload /> Standard Style
                    </span>
                  </button>
                  <div className="thumbnail mt--80 mt_md--30 mt_sm--30">
                    <img
                      src="/assets/images/preview/landing-hero-image-macbook.png"
                      alt="App Landing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Slider Area */}

        {/* Start Demo Pages */}
        <div className="landing-page-demo ptb--150" id="home">
          <div className="wraper plr--150 plr_lg--40 plr_md--40 plr_sm--30">
            <div className="landing-page-title text-center">
              <h2>Readymade Multi-use Homepages</h2>
              <p>Build product branding or app introduction with less effort</p>
            </div>
            <div className="row mt--40">
              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/branding`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-branding.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/branding`}
                      target="_blank"
                    >
                      Branding
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link to={`${process.env.PUBLIC_URL}/sass`} target="_blank">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-saas.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link to={`${process.env.PUBLIC_URL}/sass`} target="_blank">
                      sass
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/app-showcase`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-app-showcase.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/app-showcase`}
                      target="_blank"
                    >
                      app showcase
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/software`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-software.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/software`}
                      target="_blank"
                    >
                      software
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/startups`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-start-ups.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/startups`}
                      target="_blank"
                    >
                      startups
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/payment`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-payments.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/payment`}
                      target="_blank"
                    >
                      payment
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/app-landing`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-app-landing.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/app-landing`}
                      target="_blank"
                    >
                      app landing
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link to={`${process.env.PUBLIC_URL}/ebook`} target="_blank">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-ebook.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/ebook`}
                      target="_blank"
                    >
                      ebook
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link to={`${process.env.PUBLIC_URL}/clinic`} target="_blank">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-clinic.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/clinic`}
                      target="_blank"
                    >
                      clinic
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/digital-store`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-digital-store.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/digital-store`}
                      target="_blank"
                    >
                      digital-store
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}

              {/* Start Single Demo */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="demo-single">
                  <Link
                    to={`${process.env.PUBLIC_URL}/digital-agency`}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/preview/home-preview-digital-agency.jpg`}
                      className="w-100"
                      alt="Images"
                    />
                  </Link>
                  <h3 className="title">
                    <Link
                      to={`${process.env.PUBLIC_URL}/digital-agency`}
                      target="_blank"
                    >
                      Digital Agency
                    </Link>
                  </h3>
                </div>
              </div>
              {/* End Single Demo */}
            </div>
          </div>
        </div>
        {/* End Demo Pages */}

        {/* Start Landing Service Area */}
        <div className="landing-service-area theme-gradient ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="landing-section-title text-center mb--70 md_sm--50">
                  <h2 className="text-white">
                    The perfect solution <br /> for your next landing page
                    website.
                  </h2>
                  <p className="text-white">
                    Integrated premium plugins make things simple for you
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Container>
            <Row className="mt_dec--30">{DataList}</Row>
          </Container>
        </div>
        {/* End Landing Service Area */}

        {/* Start Feature Area  */}
        <div className="pre-feature-area ptb--120" id="feature">
          <Container>
            <Row className="row">
              <div className="col-lg-12">
                <div className="landing-section-title text-center mb--30 md_sm--0">
                  <h2>Features that Ensure Quality Efficiency</h2>
                  <p>
                    More than a guarantee, it’s a promise from our dedicated
                    developers as always
                  </p>
                </div>
              </div>
            </Row>
            <Row className="mt--30">{DataListTwo}</Row>
          </Container>
        </div>
        {/* End Feature Area  */}

        {/* Start Footer Area  */}
        <div className="pre-footer-area footer-bg ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center ptb--70">
                  <h2 className="mb--25 text-white">
                    Take Fastest Path of Least <br /> Effort to Success Now!
                  </h2>
                  <h6 className="mb--40 text-white">
                    Build. Develop. Succeed.
                  </h6>
                  <a
                    className="ar-button style-flat secondary"
                    href="https://1.envato.market/c/417168/275988/4415?subId1=hastheme&subId2=demo&subId3=https%3A%2F%2Fthemeforest.net%2Fuser%2Fbootxperts%2Fportfolio&u=https%3A%2F%2Fthemeforest.net%2Fuser%2Fbootxperts%2Fportfolio"
                  >
                    <span className="button-text">Join to Download</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Demo;
