import { combineReducers } from 'redux';
import { blogReducers } from "./BlogReducer";
import { portfolioReducers } from "./PortfolioReducers";


const rootReducer = combineReducers({
    blogReducers,
    portfolioReducers
});

export default rootReducer;