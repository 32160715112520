import React , { Component , Fragment } from "react";
import {Helmet} from 'react-helmet';
import HeaderOne from '../components/header/HeaderOne';
import ContactThree from '../elements/component/contact/ContactThree';

import { DigitalStoreData } from "../Home/data";
import Footer from '../components/footer/Footer';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class DigitalStore extends Component{
    render(){
        const StoreFirstFour = DigitalStoreData.slice(0 , 4);
        const StoreLastTotal = DigitalStoreData.slice(4 , DigitalStoreData.length);
        return(
            <Fragment>
                <Helmet>
                    <title>Digital Store || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--digitalstore bg_image d-flex align-items-center bg_image--18">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="inner text-center">
                                        <h3>Exclusive premium design tools</h3>
                                        <div className="slide-image-wrapepr d-flex">
                                            <div className="single-box">
                                                <img src="/assets/images/slider/photoshop-icon.png" alt="Slider Images"/>
                                            </div>
                                            <div className="single-box">
                                                <img src="/assets/images/slider/sketch-icon.png" alt="Slider Images"/>
                                            </div>
                                            <div className="single-box">
                                                <p>for Sketch and Photoshop</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="thumbnail">
                                        <img src="/assets/images/slider/digital-store.png" alt="Slider Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area */}

                {/* Start Degital Store Area */}
                <div className="digital-store-area pt--120 digitalstore-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 mb--60 mb_sm--0 mb_md--0">
                                <div className="section-title">
                                    <h2>Present <span className="color-4">an oustanding mockup collection</span> developed for higher product presentation. </h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {/* Start Single Digital Store */}
                            {StoreFirstFour.map( (value , index) => (
                                <div className="col-lg-6" key={index}>
                                    <div className="digital-store">
                                        <div className="digital-inner">
                                            <div className="thumbnail">
                                                <a href="/digital-store">
                                                    <img src={`/assets/images/digitalstore/digital-store-product-${value.image}.jpg`} alt="All Landing"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <h3 className="title">{value.title}</h3>
                                                    <span className="category">{value.category}</span>
                                                    <p>{value.description}</p>
                                                    <div className="store-footer">
                                                        <button className="ar-button style-flat" type="submit">
                                                            <span className="button-text">View product</span>
                                                        </button>
                                                        <ul className="pricelist">
                                                            {value.oldPrice ? <li className="oldprice">{value.oldPrice}</li>:''}
                                                            <li className="newprice">{value.newPrice}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* End Single Digital Store */}
                        </div>
                        <div className="row">
                            {StoreLastTotal.map((value , index) => (
                                <div className="col-lg-4" key={index}>
                                    <div className="digital-store">
                                        <div className="digital-inner">
                                            <div className="thumbnail">
                                                <a href="/digital-store">
                                                    <img src={`/assets/images/digitalstore/digital-store-product-${value.image}.jpg`} alt="All Landing"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <h3 className="title"><a href="/digital-store">{value.title}</a></h3>
                                                    <span className="category">{value.category}</span>
                                                    <p>{value.description}</p>
                                                    <div className="store-footer">
                                                        <button className="ar-button style-flat" type="submit">
                                                            <span className="button-text">View product</span>
                                                        </button>
                                                        <ul className="pricelist">
                                                            {value.oldPrice ? <li className="oldprice">{value.oldPrice}</li>:''}
                                                            <li className="newprice">{value.newPrice}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Start Button Area */}
                        <div className="row mt--75">
                            <div className="col-lg-12">
                                <div className="view-more-button text-center">
                                    <button className="ar-button style-flat solid-style" type="submit">
                                        <span className="button-text">View more</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* End Button Area */}

                        <div className="space-separator"></div>

                        {/* Start Countdown Area */}
                        <div className="countdown-area">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="section-title">
                                        <h2>One year access to all devices, plus updates. <span className="color-4">80% discount.</span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="countdown-button text-left text-lg-right mt_md--30 mt_sm--30">
                                        <button className="ar-button style-flat" type="submit">
                                            <span className="button-text">$29 Buy now</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="thumbnail text-center mt--130">
                                        <img src="/assets/images/aeroland/digital-store-image-02.png" alt="App Landing"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Countdown Area */}
                    </div>
                </div>

                <ContactThree />
                {/* End Degital Store Area */}


                {/* Start Footer Area */}
                <Footer />
                {/* End Footer Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}               

            </Fragment>
        )
    }
}

export default DigitalStore;
