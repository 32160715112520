import React, { Component } from "react";

class Subscribe extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fNameValue: '',
            emailValue: '',
        };
    };

    render(){
        return(
            <div className="inner">
                <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="POST" noValidate>
                    <label className="form-item" htmlFor='AERO1'>
                        <input 
                            type="text" 
                            name="FNAME" 
                            id="AERO1" 
                            value={this.state.fNameValue} 
                            onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                            placeholder="Your Name"
                        />
                    </label>
                    <label className="form-item" htmlFor='AERO2'>
                        <input 
                            type="email" 
                            name="EMAIL" 
                            id="AERO2"
                            value={this.state.emailValue} 
                            onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
                            autoCapitalize="off" 
                            autoCorrect="off"
                            placeholder="Email"
                        /> 
                    </label>
                    <button className="form-item ar-button style-flat" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>
                </form>
            </div>    
        )
    }
}
export default Subscribe;
